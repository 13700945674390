<template>
  <div class="container-fluid">
    <div class="page-header min-height-300 border-radius-xl mt-4">
      <img
        src="@/assets/img/profile.png"
        alt="profile_image"
        class="shadow-sm w-100 border-radius-lg"
      />
      <span class="mask bg-transparent opacity-6"></span>
    </div>
    <div class="card card-body mx-3 mx-md-4 mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar rounded-circle avatar-xl position-relative">
            <img
              src="@/assets/img/collections/wm.png"
              alt="profile_image"
              class="w-100 border-radius-lg"
            />
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1" v-if="this.user">
              <a
                :href="
                  'https://etherscan.io/address/' + this.user.get('ethAddress')
                "
              >
                {{
                  this.user
                    ? getEllipsisTxt(this.user.get("ethAddress"), 5)
                    : ""
                }}
              </a>
            </h5>
            <!--            <p class="mb-0 font-weight-normal text-sm">CEO / Co-Founder</p>-->
          </div>
        </div>
        <div
          class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
        >
          <div class="nav-wrapper position-relative end-0">
            <ul class="p-1 bg-transparent nav nav-pills nav-fill">
              <li class="nav-item">
                <h5 class="mb-1">
                  {{ parseFloat(claimAmount).toFixed(2) }}
                  <span class="text-sm">$WRLD</span>
                </h5>
                <p class="mb-0 font-weight-normal text-sm">
                  Claimable Kickback
                </p>
              </li>
              <li class="nav-item"></li>
              <template v-if="claimInProgress">
                <li class="nav-item justify-content-center">
                  <button
                    class="btn btn-primary disabled"
                    type="button"
                    disabled
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Claiming...
                  </button>
                </li>
              </template>
              <template v-else>
                <template v-if="claimAmount > 0">
                  <li class="nav-item justify-content-center">
                    <button
                      class="btn btn-primary"
                      v-on:click="claimKickback(this.kickback)"
                    >
                      Claim
                    </button>
                  </li>
                </template>
                <template v-else>
                  <li class="nav-item justify-content-center">
                    <button class="btn btn-primary disabled">Claim</button>
                  </li>
                </template>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="row mt-4">
        <div class="col-12">
          <div class="mb-1 ps-3">
            <!--            <h4 class="mb-1">Owned NFTS</h4>-->
          </div>
        </div>
      </div>
      <div class="row row-whith-wide-cards">
        <AccountNfts />
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import AccountNfts from "../components/AccountNfts";
import useMoralis from "@/composables/useMoralis";
import { getEllipsisTxt } from "@/helpers/formatters";
import Moralis from "moralis";

export default {
  name: "profile-overview",
  setup() {
    const {
      authenticate,
      logout,
      isAuthenticated,
      isAuthenticating,
      user,
    } = useMoralis();
    return {
      getEllipsisTxt,
      authenticate,
      logout,
      isAuthenticated,
      isAuthenticating,
      user,
    };
  },
  data() {
    return {
      showMenu: false,
      claimAmount: 0,
      kickback: null,
      claimInProgress: false,
    };
  },
  components: { AccountNfts },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
    if (this.isAuthenticated) {
      // pull listing
      const KickbackBalance = Moralis.Object.extend("KickbackBalance");
      const KickbackBalanceQuery = new Moralis.Query(KickbackBalance);
      KickbackBalanceQuery.equalTo(
        "address",
        Moralis.User.current().get("ethAddress")
      );
      KickbackBalanceQuery.first().then((kickback) => {
        if (kickback) {
          this.claimAmount = Moralis.Units.FromWei(
            kickback.attributes.available_balance
          );
          this.kickback = kickback;
        }
      });
    }
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async claimKickback(kickback) {
      const marketPlaceAbi = [
        {
          inputs: [
            {
              internalType: "uint256",
              name: "_amount",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "_nounce",
              type: "uint256",
            },
            {
              internalType: "bytes",
              name: "_signature",
              type: "bytes",
            },
          ],
          name: "claim",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_wrldToken",
              type: "address",
            },
          ],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "seller",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "buyer",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "hostContract",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "price",
              type: "uint256",
            },
          ],
          name: "ListingPurchased",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "previousOwner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "amount",
              type: "uint256",
            },
          ],
          name: "kickbackClaimed",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_contract",
              type: "address",
            },
            {
              internalType: "address",
              name: "_wallet",
              type: "address",
            },
          ],
          name: "ownerSetCreatorWallet",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_seller",
              type: "address",
            },
            {
              internalType: "address",
              name: "_hostContract",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "_price",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "_tokenId",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "nounce",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "_creatorPercent",
              type: "uint256",
            },
            {
              internalType: "bytes",
              name: "_signature",
              type: "bytes",
            },
          ],
          name: "purchase",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [],
          name: "renounceOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint16",
              name: "_percent",
              type: "uint16",
            },
          ],
          name: "setCommunityPercent",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_contract",
              type: "address",
            },
            {
              internalType: "address",
              name: "_wallet",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "_nounce",
              type: "uint256",
            },
            {
              internalType: "bytes",
              name: "_signature",
              type: "bytes",
            },
          ],
          name: "setCreatorWallet",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_wallet",
              type: "address",
            },
          ],
          name: "setPayoutWallet",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "transferOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "withdraw",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [],
          name: "communityPercent",
          outputs: [
            {
              internalType: "uint16",
              name: "",
              type: "uint16",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_contract",
              type: "address",
            },
          ],
          name: "getCreatorWallet",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "owner",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "payoutWallet",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "wrld",
          outputs: [
            {
              internalType: "contract kWRLD_Token_Ethereum",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ];
      this.claimInProgress = true;
      if (kickback.attributes.status === "in_progress") {
        const options = {
          chain: "0x1",
          contractAddress: "0x22B03D85eeff6F5F0157c9B3981Ca2E5dE031922",
          functionName: "claim",
          msgValue: 0,
          abi: marketPlaceAbi,
          params: {
            _amount: Moralis.Units.ETH(this.claimAmount),
            _nounce: kickback.attributes.data.nounce,
            _signature: kickback.attributes.data.sig,
          },
        };
        try {
          const claiming = await Moralis.executeFunction(options);
          await claiming.wait();
          this.claimInProgress = false;
          this.claimAmount = 0;
          this.$notify({
            title: "Kickback Claimed",
            text: "Your kickback has been claimed",
            type: "success",
          });
        } catch (e) {
          this.claimInProgress = false;
          this.$notify({
            title: "Kickback Claim Failed - Contact Support",
            text: e.message,
            type: "error",
          });
        }
      } else {
        Moralis.Cloud.run("claim", {}).then(
          async (res) => {
            const options = {
              chain: "0x1",
              contractAddress: "0x22B03D85eeff6F5F0157c9B3981Ca2E5dE031922",
              functionName: "claim",
              msgValue: 0,
              abi: marketPlaceAbi,
              params: {
                _amount: Moralis.Units.ETH(this.claimAmount),
                _nounce: res.nounce,
                _signature: res.sig,
              },
            };
            try {
              const claiming = await Moralis.executeFunction(options);
              await claiming.wait();
              this.claimInProgress = false;
              this.claimAmount = 0;
              this.$notify({
                title: "Kickback Claimed",
                text: "Your kickback has been claimed",
                type: "success",
              });
            } catch (e) {
              this.claimInProgress = false;
              this.$notify({
                title: "Kickback Claim Failed - Contact Support",
                text: e.message,
                type: "error",
              });
            }
          },
          (error) => {
            console.log(error);
            alert("Failed to claim kickback. Please reach out to support");
          }
        );
      }
    },
  },
};
</script>

<style>
.row-whith-wide-cards .card {
  width: 100%;
}
</style>
