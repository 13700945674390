<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-0">Staking Stats</h6>
        <button
          type="button"
          class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title
          data-bs-original-title="See staking/unstaking breakdown"
        >
          <i class="fas fa-info" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="p-3 pb-0 mt-4 card-body">
      <div class="row">
        <div class="col-7 text-start">
          <div class="chart">
            <canvas :id="chartId" class="chart-canvas" height="200"></canvas>
          </div>
        </div>
        <div class="my-auto col-5">
          <span class="badge badge-md badge-dot me-4 d-block text-start">
            <i class="bg-primary"></i>
            <span class="text-xs text-dark">Staked</span>
          </span>
          <span class="badge badge-md badge-dot me-4 d-block text-start">
            <i class="bg-dark"></i>
            <span class="text-xs text-dark">Unstaked</span>
          </span>
        </div>
      </div>
    </div>
    <div class="p-3 pt-0 pb-0 card-footer d-flex align-items-center">
      <div class="w-60">
        <p class="text-sm">
          Currently
          <b>{{ this.totalStaked }}</b> All-Terrain passports are staked, and
          <b>{{ 1000 - this.totalStaked }}</b> All-Terrain passports are
          circulating the open markets.
        </p>
      </div>
      <div class="w-40 text-end">
        <a
          class="mb-0 btn bg-light text-end"
          href="https://docs.wrldmind.com/staking"
          >Read more</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import Moralis from "moralis";
export default {
  name: "staking-pie-card",
  data() {
    return {
      chartId: "chart-pie",
      totalStaked: 1,
    };
  },
  mounted() {
    const stakingAbi = [
      {
        inputs: [
          { internalType: "address", name: "_address", type: "address" },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint16",
            name: "token_id",
            type: "uint16",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "timestamp",
            type: "uint256",
          },
        ],
        name: "AT_Staked",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint16",
            name: "token_id",
            type: "uint16",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "timestamp",
            type: "uint256",
          },
        ],
        name: "AT_UnStaked",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "Paused",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "Unpaused",
        type: "event",
      },
      {
        inputs: [],
        name: "AT",
        outputs: [
          { internalType: "contract ERC721", name: "", type: "address" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "MIN_STAKE_TIME",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint16", name: "", type: "uint16" }],
        name: "at_stakes",
        outputs: [
          { internalType: "address", name: "owner", type: "address" },
          { internalType: "uint256", name: "timestamp", type: "uint256" },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "uint16[]", name: "_token_ids", type: "uint16[]" },
        ],
        name: "bulkStake",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "operator", type: "address" },
          { internalType: "address", name: "", type: "address" },
          { internalType: "uint256", name: "", type: "uint256" },
          { internalType: "bytes", name: "", type: "bytes" },
        ],
        name: "onERC721Received",
        outputs: [{ internalType: "bytes4", name: "", type: "bytes4" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [{ internalType: "address", name: "", type: "address" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "pause",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "paused",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint256", name: "_time", type: "uint256" }],
        name: "setMinStakeTime",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint16", name: "_token_id", type: "uint16" }],
        name: "stake",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "total_staked",
        outputs: [{ internalType: "uint16", name: "", type: "uint16" }],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          { internalType: "address", name: "newOwner", type: "address" },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [{ internalType: "uint16", name: "_token_id", type: "uint16" }],
        name: "unStake",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "unpause",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];
    const stakeOptions = {
      chain: "0x1",
      address: "0x7E506f97a7767EF7C2d6233B90fB57d729761b61",
      function_name: "total_staked",
      abi: stakingAbi,
      params: {},
    };
    Moralis.Web3API.native.runContractFunction(stakeOptions).then((data) => {
      var pieChart = document.getElementById(this.chartId).getContext("2d");
      this.totalStaked = data;
      var unstaked = 1000 - parseInt(data);
      // Pie chart
      new Chart(pieChart, {
        type: "pie",
        data: {
          labels: ["Staked", "Unstaked"],
          datasets: [
            {
              label: "Projects",
              weight: 9,
              cutout: 0,
              tension: 0.9,
              pointRadius: 2,
              borderWidth: 2,
              backgroundColor: ["#cb0c9f", "#3A416F"],
              data: [data, unstaked],
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
          },
        },
      });
    });
  },
};
</script>
