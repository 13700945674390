<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mt-4 col-lg-6 col-sm-6 mt-sm-0">
        <div class="card">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">Stake</h6>
              <button
                type="button"
                class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title
                data-bs-original-title="Stake your All-Terrain Passport"
              >
                <i class="fas fa-info" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <div class="col-lg-3 text-center">
                <template v-if="this.userNfts.length > 1">
                  <div class="dropdown">
                    <a
                      href="javascript:;"
                      class="btn bg-gradient-dark dropdown-toggle"
                      data-bs-toggle="dropdown"
                      id="navbarDropdownMenuLink2"
                    >
                      Select Passport To Stake
                    </a>
                    <ul
                      class="dropdown-menu dropdown-menu-animation"
                      aria-labelledby="navbarDropdownMenuLink2"
                    >
                      <template v-for="nft in userNfts" v-bind:key="nft">
                        <li v-on:click="setSelectedNft(nft)">
                          <a class="dropdown-item" href="javascript:;">{{
                            nft
                          }}</a>
                        </li>
                      </template>
                    </ul>
                  </div>
                </template>
                <template v-else>
                  <div class="dropdown">
                    <a
                      href="javascript:;"
                      class="btn bg-gradient-dark dropdown-toggle disabled"
                      data-bs-toggle="dropdown"
                      id="navbarDropdownMenuLink2"
                    >
                      No Passports available
                    </a>
                    <ul
                      class="dropdown-menu dropdown-menu-animation"
                      aria-labelledby="navbarDropdownMenuLink2"
                    >
                      <template v-for="nft in userNfts" v-bind:key="nft">
                        <li v-on:click="setSelectedNft(nft)">
                          <a class="dropdown-item" href="javascript:;">{{
                            nft
                          }}</a>
                        </li>
                      </template>
                    </ul>
                  </div>
                </template>
              </div>
              <div class="col-12 text-center">
                <h6>Selected NFT to stake:</h6>
                <p class="text-bold text-lg text-primary">
                  {{
                    this.selectedNftForStaking == null
                      ? "-"
                      : this.selectedNftForStaking
                  }}
                </p>
                <template v-if="approved">
                  <div class="col-12 text-center">
                    <template
                      v-if="
                        userNfts.length > 1 && selectedNftForStaking != null
                      "
                    >
                      <template v-if="stakingLoading">
                        <button
                          class="btn btn-lg btn-rounded btn-primary"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Cooking the 🥩...
                        </button>
                      </template>
                      <template v-else>
                        <button
                          class="btn btn-lg btn-rounded btn-primary"
                          v-on:click="stake()"
                        >
                          Stake
                        </button>
                      </template>
                    </template>
                    <template v-else>
                      <button
                        class="btn btn-lg btn-rounded btn-primary"
                        disabled
                      >
                        Stake
                      </button>
                    </template>
                  </div>
                </template>
                <template v-else>
                  <div class="col-12 text-center">
                    <template v-if="userNfts.length > 1">
                      <template v-if="approvalLoading">
                        <button
                          class="btn btn-lg btn-rounded btn-primary"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Approving...
                        </button>
                      </template>
                      <template v-else>
                        <button
                          v-on:click="approval()"
                          class="btn btn-lg btn-rounded btn-primary"
                        >
                          Approve
                        </button>
                      </template>
                    </template>
                    <template v-else>
                      <button
                        class="btn btn-lg btn-rounded btn-primary"
                        disabled
                      >
                        Approve
                      </button>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 col-lg-6 col-sm-6 mt-sm-0">
        <div class="card">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">Unstake</h6>
              <button
                type="button"
                class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title
                data-bs-original-title="Unstake your All-Terrain Passport"
              >
                <i class="fas fa-info" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <div class="col-lg-3 text-center">
                <template v-if="this.stakedUserNfts.length > 0">
                  <div class="dropdown">
                    <a
                      href="javascript:;"
                      class="btn bg-gradient-dark dropdown-toggle"
                      data-bs-toggle="dropdown"
                      id="navbarDropdownMenuLink2"
                    >
                      Select Passport To Unstake
                    </a>
                    <ul
                      class="dropdown-menu dropdown-menu-animation"
                      aria-labelledby="navbarDropdownMenuLink2"
                    >
                      <template v-for="nft in stakedUserNfts" v-bind:key="nft">
                        <li v-on:click="setUnstakedSelectedNft(nft)">
                          <a class="dropdown-item" href="javascript:;">{{
                            nft
                          }}</a>
                        </li>
                      </template>
                    </ul>
                  </div>
                </template>
                <template v-else>
                  <div class="dropdown">
                    <a
                      href="javascript:;"
                      class="btn bg-gradient-dark dropdown-toggle disabled"
                      data-bs-toggle="dropdown"
                      id="navbarDropdownMenuLink2"
                    >
                      No Passports Available
                    </a>
                    <ul
                      class="dropdown-menu dropdown-menu-animation"
                      aria-labelledby="navbarDropdownMenuLink2"
                    >
                      <template v-for="nft in stakedUserNfts" v-bind:key="nft">
                        <li v-on:click="setUnstakedSelectedNft(nft)">
                          <a class="dropdown-item" href="javascript:;">{{
                            nft
                          }}</a>
                        </li>
                      </template>
                    </ul>
                  </div>
                </template>
              </div>
              <div class="col-12 text-center">
                <h6>Selected NFT to Unstake:</h6>
                <p class="text-bold text-lg text-primary">
                  {{
                    this.selectedNftForUnstaking == null
                      ? "-"
                      : this.selectedNftForUnstaking
                  }}
                </p>
                <div class="col-12 text-center">
                  <template
                    v-if="
                      stakedUserNfts.length > 0 &&
                      selectedNftForUnstaking != null
                    "
                  >
                    <template v-if="unstakingLoading">
                      <button
                        class="btn btn-lg btn-rounded btn-primary"
                        disabled
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Unstaking...
                      </button>
                    </template>
                    <template v-else>
                      <button
                        class="btn btn-lg btn-rounded btn-primary"
                        v-on:click="unStake()"
                      >
                        UnStake
                      </button>
                    </template>
                  </template>
                  <template v-else>
                    <button class="btn btn-lg btn-rounded btn-primary" disabled>
                      UnStake
                    </button>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-6 col-12 mb-4">
        <staking-pie-chart></staking-pie-chart>
      </div>
      <div class="col-md-6 col-12 mb-4">
        <div class="card">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-0">Your Staked NFTs</h6>
              <button
                type="button"
                class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title
                data-bs-original-title="View your staked passports, and the unlock time."
              >
                <i class="fas fa-info" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Token Id
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Staked At
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Unstaking Unlocks At
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="originalStakedNfts.length < 1">
                  <div
                    class="col-md-12 col-12 mb-4 justify-content-center text-center"
                  >
                    <p class="text-md font-weight-bold mb-0">
                      You have 0 Staked Passports at this time.
                    </p>
                  </div>
                </template>
                <template v-else>
                  <template v-for="nft in originalStakedNfts" :key="nft.id">
                    <tr>
                      <td class="align-middle text-center text-sm">
                        <h6 class="mb-0 text-xs">
                          {{ nft.attributes.token_id }}
                        </h6>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <p class="text-xs font-weight-bold mb-0">
                          {{
                            this.getStakedTimestamp(nft.attributes.timestamp)
                          }}
                        </p>
                      </td>
                      <td class="align-middle text-center text-sm">
                        <p class="text-xs text-success font-weight-bold mb-0">
                          {{
                            this.getUnStakeTimestamp(nft.attributes.timestamp)
                          }}
                        </p>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import Moralis from "moralis";
import useMoralis from "@/composables/useMoralis";
import { getEllipsisTxt } from "@/helpers/formatters";
import StakingPieChart from "@/views/StakingPieChart";

export default {
  name: "Staking",
  setup() {
    const {
      authenticate,
      logout,
      isAuthenticated,
      isAuthenticating,
      user,
    } = useMoralis();
    return {
      getEllipsisTxt,
      authenticate,
      logout,
      isAuthenticated,
      isAuthenticating,
      user,
    };
  },
  data() {
    return {
      userNfts: ["Select All"],
      selectedNftForStaking: null,
      stakedUserNfts: [],
      selectedNftForUnstaking: null,
      approvalLoading: false,
      approved: false,
      stakingLoading: false,
      orginalUserNfts: [],
      unstakingLoading: false,
      originalStakedNfts: [],
      requiredStakeTime: 1209600,
    };
  },
  components: { StakingPieChart },
  mounted() {
    setTooltip();
    if (this.isAuthenticated) {
      const options = {
        chain: "0x1",
        address: Moralis.User.current().get("ethAddress"),
        token_addresses: ["0xad1801d5d7a5f2ea3bc989a6540a81f46820fde3"],
      };
      Moralis.Web3API.account.getNFTs(options).then((data) => {
        for (let i = 0; i < data.result.length; i++) {
          this.orginalUserNfts.push(data.result[i].token_id);
          this.userNfts.push(data.result[i].token_id);
        }
      });

      const stakingAbi = [
        {
          inputs: [
            { internalType: "address", name: "_address", type: "address" },
          ],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint16",
              name: "token_id",
              type: "uint16",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "timestamp",
              type: "uint256",
            },
          ],
          name: "AT_Staked",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint16",
              name: "token_id",
              type: "uint16",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "timestamp",
              type: "uint256",
            },
          ],
          name: "AT_UnStaked",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "previousOwner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "Paused",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "Unpaused",
          type: "event",
        },
        {
          inputs: [],
          name: "AT",
          outputs: [
            { internalType: "contract ERC721", name: "", type: "address" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "MIN_STAKE_TIME",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [{ internalType: "uint16", name: "", type: "uint16" }],
          name: "at_stakes",
          outputs: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "uint256", name: "timestamp", type: "uint256" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint16[]", name: "_token_ids", type: "uint16[]" },
          ],
          name: "bulkStake",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "operator", type: "address" },
            { internalType: "address", name: "", type: "address" },
            { internalType: "uint256", name: "", type: "uint256" },
            { internalType: "bytes", name: "", type: "bytes" },
          ],
          name: "onERC721Received",
          outputs: [{ internalType: "bytes4", name: "", type: "bytes4" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "owner",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "pause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "paused",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "renounceOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [{ internalType: "uint256", name: "_time", type: "uint256" }],
          name: "setMinStakeTime",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint16", name: "_token_id", type: "uint16" },
          ],
          name: "stake",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "total_staked",
          outputs: [{ internalType: "uint16", name: "", type: "uint16" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "newOwner", type: "address" },
          ],
          name: "transferOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint16", name: "_token_id", type: "uint16" },
          ],
          name: "unStake",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "unpause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ];
      const stakeOptions = {
        chain: "0x1",
        address: "0x7E506f97a7767EF7C2d6233B90fB57d729761b61",
        function_name: "MIN_STAKE_TIME",
        abi: stakingAbi,
        params: {},
      };
      Moralis.Web3API.native.runContractFunction(stakeOptions).then((data) => {
        this.requiredStakeTime = data;
        const staked = Moralis.Object.extend("Staked");
        const query = new Moralis.Query(staked);
        query.equalTo("address", Moralis.User.current().get("ethAddress"));
        query.find().then((listings) => {
          for (let i = 0; i < listings.length; i++) {
            this.stakedUserNfts.push(listings[i].attributes.token_id);
            this.originalStakedNfts.push(listings[i]);
          }
        });
      });

      const genericAbi = [
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "approved",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "Approval",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "operator",
              type: "address",
            },
            {
              indexed: false,
              internalType: "bool",
              name: "approved",
              type: "bool",
            },
          ],
          name: "ApprovalForAll",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "approve",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "getApproved",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              internalType: "address",
              name: "operator",
              type: "address",
            },
          ],
          name: "isApprovedForAll",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "operator",
              type: "address",
            },
            {
              internalType: "bool",
              name: "approved",
              type: "bool",
            },
          ],
          name: "setApprovalForAll",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ];
      const approvalOptions = {
        chain: "0x1",
        address: "0xaD1801D5D7A5f2ea3bC989a6540A81F46820fdE3",
        function_name: "isApprovedForAll",
        abi: genericAbi,
        params: {
          owner: Moralis.User.current().get("ethAddress"),
          operator: "0x7E506f97a7767EF7C2d6233B90fB57d729761b61",
        },
      };
      Moralis.Web3API.native
        .runContractFunction(approvalOptions)
        .then((data) => {
          this.approved = data;
        });
    }
  },
  methods: {
    setSelectedNft(nft_id) {
      this.selectedNftForStaking = nft_id;
    },
    setUnstakedSelectedNft(nft_id) {
      this.selectedNftForUnstaking = nft_id;
    },
    async approval() {
      const genericAbi = [
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "approved",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "Approval",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "operator",
              type: "address",
            },
            {
              indexed: false,
              internalType: "bool",
              name: "approved",
              type: "bool",
            },
          ],
          name: "ApprovalForAll",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "approve",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "getApproved",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              internalType: "address",
              name: "operator",
              type: "address",
            },
          ],
          name: "isApprovedForAll",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "operator",
              type: "address",
            },
            {
              internalType: "bool",
              name: "approved",
              type: "bool",
            },
          ],
          name: "setApprovalForAll",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ];
      this.approvalLoading = true;
      const approvalOptions = {
        chain: "0x1",
        contractAddress: "0xaD1801D5D7A5f2ea3bC989a6540A81F46820fdE3",
        functionName: "setApprovalForAll",
        abi: genericAbi,
        params: {
          operator: "0x7E506f97a7767EF7C2d6233B90fB57d729761b61",
          approved: true,
        },
      };
      const approval = await Moralis.executeFunction(approvalOptions);
      await approval.wait();
      this.approvalLoading = false;
      this.approved = true;
    },
    async stake() {
      this.stakingLoading = true;
      const stakingAbi = [
        {
          inputs: [
            { internalType: "address", name: "_address", type: "address" },
          ],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint16",
              name: "token_id",
              type: "uint16",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "timestamp",
              type: "uint256",
            },
          ],
          name: "AT_Staked",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint16",
              name: "token_id",
              type: "uint16",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "timestamp",
              type: "uint256",
            },
          ],
          name: "AT_UnStaked",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "previousOwner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "Paused",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "Unpaused",
          type: "event",
        },
        {
          inputs: [],
          name: "AT",
          outputs: [
            { internalType: "contract ERC721", name: "", type: "address" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "MIN_STAKE_TIME",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [{ internalType: "uint16", name: "", type: "uint16" }],
          name: "at_stakes",
          outputs: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "uint256", name: "timestamp", type: "uint256" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint16[]", name: "_token_ids", type: "uint16[]" },
          ],
          name: "bulkStake",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "operator", type: "address" },
            { internalType: "address", name: "", type: "address" },
            { internalType: "uint256", name: "", type: "uint256" },
            { internalType: "bytes", name: "", type: "bytes" },
          ],
          name: "onERC721Received",
          outputs: [{ internalType: "bytes4", name: "", type: "bytes4" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "owner",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "pause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "paused",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "renounceOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [{ internalType: "uint256", name: "_time", type: "uint256" }],
          name: "setMinStakeTime",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint16", name: "_token_id", type: "uint16" },
          ],
          name: "stake",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "total_staked",
          outputs: [{ internalType: "uint16", name: "", type: "uint16" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "newOwner", type: "address" },
          ],
          name: "transferOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint16", name: "_token_id", type: "uint16" },
          ],
          name: "unStake",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "unpause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ];
      if (this.selectedNftForStaking === "Select All") {
        const stakeOptions = {
          chain: "0x1",
          contractAddress: "0x7E506f97a7767EF7C2d6233B90fB57d729761b61",
          functionName: "bulkStake",
          abi: stakingAbi,
          params: {
            _token_ids: this.orginalUserNfts,
          },
        };
        const staking = await Moralis.executeFunction(stakeOptions);
        await staking.wait();
        this.stakingLoading = false;
      } else {
        const stakeOptions = {
          chain: "0x1",
          contractAddress: "0x7E506f97a7767EF7C2d6233B90fB57d729761b61",
          functionName: "stake",
          abi: stakingAbi,
          params: {
            _token_id: this.selectedNftForStaking,
          },
        };
        const staking = await Moralis.executeFunction(stakeOptions);
        await staking.wait();
        this.stakingLoading = false;
        this.$router.go();
      }
    },
    getStakedTimestamp(timestamp) {
      let t = new Date(1970, 0, 1); // Epoch
      t.setSeconds(timestamp);
      return t.toLocaleDateString();
    },
    getUnStakeTimestamp(timestamp) {
      let t = new Date(1970, 0, 1); // Epoch
      // 1209600 is two weeks in seconds
      console.log(this.requiredStakeTime);
      t.setSeconds(`${parseInt(timestamp) + parseInt(this.requiredStakeTime)}`);
      return t.toLocaleDateString();
    },
    async unStake() {
      this.unstakingLoading = true;
      const stakingAbi = [
        {
          inputs: [
            { internalType: "address", name: "_address", type: "address" },
          ],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint16",
              name: "token_id",
              type: "uint16",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "timestamp",
              type: "uint256",
            },
          ],
          name: "AT_Staked",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint16",
              name: "token_id",
              type: "uint16",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "timestamp",
              type: "uint256",
            },
          ],
          name: "AT_UnStaked",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "previousOwner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "Paused",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "Unpaused",
          type: "event",
        },
        {
          inputs: [],
          name: "AT",
          outputs: [
            { internalType: "contract ERC721", name: "", type: "address" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "MIN_STAKE_TIME",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [{ internalType: "uint16", name: "", type: "uint16" }],
          name: "at_stakes",
          outputs: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "uint256", name: "timestamp", type: "uint256" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint16[]", name: "_token_ids", type: "uint16[]" },
          ],
          name: "bulkStake",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "operator", type: "address" },
            { internalType: "address", name: "", type: "address" },
            { internalType: "uint256", name: "", type: "uint256" },
            { internalType: "bytes", name: "", type: "bytes" },
          ],
          name: "onERC721Received",
          outputs: [{ internalType: "bytes4", name: "", type: "bytes4" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "owner",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "pause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "paused",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "renounceOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [{ internalType: "uint256", name: "_time", type: "uint256" }],
          name: "setMinStakeTime",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint16", name: "_token_id", type: "uint16" },
          ],
          name: "stake",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "total_staked",
          outputs: [{ internalType: "uint16", name: "", type: "uint16" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "newOwner", type: "address" },
          ],
          name: "transferOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint16", name: "_token_id", type: "uint16" },
          ],
          name: "unStake",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "unpause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ];
      const stakeOptions = {
        chain: "0x1",
        contractAddress: "0x7E506f97a7767EF7C2d6233B90fB57d729761b61",
        functionName: "unStake",
        abi: stakingAbi,
        params: {
          _token_id: this.selectedNftForUnstaking,
        },
      };
      const staking = await Moralis.executeFunction(stakeOptions);
      await staking.wait();
      this.unstakingLoading = false;
      this.$router.go();
    },
  },
};
</script>
