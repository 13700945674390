<template>
  <template v-for="nft in userNfts" :key="nft.id">
    <div class="col-xl-3 col-md-6 mb-xl-0 mt-5 d-flex align-items-stretch">
      <div class="card">
        <div
          class="card-header p-0 mx-3 mt-3 position-relative z-index-1 align-items-center justify-content-between"
        >
          <a class="d-block border-radius-xl">
            <template v-if="nft.image">
              <template v-if="nft.image.includes('<svg')">
                <div class="img-fluid border-radius-xl" v-html="nft.image" />
              </template>
              <template v-else>
                <img
                  :src="nft.image"
                  alt="nft-image"
                  class="img-fluid border-radius-xl"
                  width="500"
                  height="500"
                />
              </template>
            </template>
            <template v-else>
              <img
                src="@/assets/img/logo-no-metadata.png"
                alt="img-blur-shadow"
                class="img-fluid border-radius-xl"
                width="500"
                height="500"
              />
            </template>
          </a>
        </div>
        <div class="card-body pt-2">
          <p class="mb-0 text-sm" v-if="nft.token_id">
            #{{ nft.token_id.length > 5 ? "N/A" : nft.token_id }}
          </p>
          <h5>{{ nft.name }}</h5>
          <span class="badge badge-sm bg-gradient-primary" v-if="nft.listed">{{
            "Listed"
          }}</span>
        </div>
        <div class="card-footer">
          <div class="d-flex align-items-center justify-content-between">
            <a
              v-on:click="refreshMetadata(nft.token_address, nft.token_id)"
              href="javascript:;"
              class="text-secondary icon-move-right"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-repeat"
                viewBox="0 0 16 16"
              >
                <path
                  d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"
                />
                <path
                  fill-rule="evenodd"
                  d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                />
              </svg>
              Refresh
            </a>
            <router-link
              class="text-info icon-move-right"
              :to="{
                name: 'NftDetails',
                query: {
                  token_address: nft.token_address,
                  token_id: nft.token_id,
                },
              }"
              >Details
              <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { getEllipsisTxt } from "../helpers/formatters.js";
import useMoralis from "../composables/useMoralis";
import Moralis from "moralis";
import contractToCollectionsMap from "@/contractToCollectionMap";
// import VmdInput from "./VmdInput";

export default {
  components: {},
  setup() {
    const {
      authenticate,
      logout,
      isAuthenticated,
      isAuthenticating,
      user,
    } = useMoralis();
    return {
      getEllipsisTxt,
      authenticate,
      logout,
      isAuthenticated,
      isAuthenticating,
      user,
    };
  },
  data() {
    return {
      userNfts: null,
      selectedNft: {
        token_id: null,
        name: null,
        metadata: null,
        sale_amount: null,
        token_address: "",
      },
      listedNfts: null,
      listingFormError: {
        error: false,
        message: null,
      },
      contractToCollectionsMap,
    };
  },
  mounted() {
    if (this.isAuthenticated) {
      const options = {
        chain: "0x1",
        address: Moralis.User.current().get("ethAddress"),
      };
      Moralis.Web3API.account.getNFTs(options).then((data) => {
        this.userNfts = data.result;
        for (let i = this.userNfts.length - 1; i >= 0; i--) {
          if (
            this.contractToCollectionsMap[
              this.userNfts[i].token_address.toLowerCase()
            ]
          ) {
            console.log("Supported NFT");
          } else {
            this.userNfts.splice(i, 1);
          }
        }
        for (let i = this.userNfts.length - 1; i >= 0; i--) {
          // Filtering
          // if (this.userNfts[i].contract_type == "ERC1155") {
          //   this.userNfts.splice(i, 1);
          //   continue;
          // }
          // pull listing
          const Listing = Moralis.Object.extend("Listing");
          const query = new Moralis.Query(Listing);
          this.userNfts[i].listed = false;
          this.userNfts[i].listing = null;
          query.equalTo("token_address", this.userNfts[i].token_address);
          query.equalTo("token_id", this.userNfts[i].token_id);
          query.equalTo("seller", this.user.get("ethAddress"));
          query.equalTo("buyer", null);
          query.find().then((listing) => {
            if (listing.length == 1) {
              this.userNfts[i].listed = true;
              this.userNfts[i].listing = listing;
            } else {
              this.userNfts[i].listed = false;
              this.userNfts[i].listing = null;
            }
          });
          if (
            this.userNfts[i].metadata == null ||
            JSON.parse(this.userNfts[i].metadata).image == null
          ) {
            // const options = {
            //   address: this.userNfts[i]["token_address"],
            //   token_id: this.userNfts[i]["token_id"],
            //   flag: "token_uri",
            // };
            // Moralis.Web3API.token.reSyncMetadata(options);
          } else {
            if (
              JSON.parse(this.userNfts[i].metadata).image.startsWith("ipfs")
            ) {
              this.userNfts[i].image =
                "https://ipfs.moralis.io:2053/ipfs/" +
                JSON.parse(this.userNfts[i].metadata)
                  .image.split("ipfs://")
                  .slice(-1)[0];
            } else {
              this.userNfts[i].image = JSON.parse(
                this.userNfts[i].metadata
              ).image;
              if (this.userNfts[i].image.includes("<svg")) {
                const regex = new RegExp("width='\\d+' height='\\d+'");
                this.userNfts[i].image = this.userNfts[i].image.replace(
                  regex,
                  "width='100%' height='100%'"
                );
              }
            }
          }
        }
      });
    }
  },
  methods: {
    refreshMetadata(address, id) {
      const options = {
        address: address,
        token_id: id,
        flag: "uri",
      };
      Moralis.Web3API.token.reSyncMetadata(options);
      this.$notify({
        title: "Refresh Requested",
        text: "We've queued this item for an update! Check back later...",
        type: "success",
      });
    },
    selectNft(item) {
      this.selectedNft = item;
    },
  },
};
</script>
