<template>
  <div class="py-4 container-fluid">
    <div class="row mb-5">
      <div class="col-lg-4 position-relative z-index-2 text-center"></div>
      <div class="col-lg-4 position-relative z-index-2 text-center">
        <div class="input-group input-group-outline">
          <input
            placeholder="Search"
            type="text"
            class="form-control b text-bold text-dark"
            v-model="searchQuery"
          />
        </div>
      </div>
      <div class="col-lg-4 position-relative z-index-2 text-center"></div>
    </div>
    <div class="row mb-5 row-whith-wide-cards">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row mb-4">
          <template
            v-for="collection in filteredCollections"
            :key="collection.id"
          >
            <div
              class="col-xl-4 col-md-6 mb-xl-0 mt-5 d-flex align-items-stretch text-center"
            >
              <div class="card" data-animation="false">
                <div class="card-header">
                  <a class="">
                    <router-link
                      class="text-dark text-bold icon-move-right"
                      :to="{
                        name: 'Collection',
                        query: {
                          contract: collection.contract,
                        },
                      }"
                    >
                      <img
                        :src="collection.img"
                        alt="nft-image"
                        class="img-fluid"
                        width="130"
                        height="130"
                      />
                    </router-link>
                  </a>
                </div>
                <div class="card-body text-center">
                  <h5 class="font-weight-normal mt-0">
                    <!--                    /collection?contract=0x5B97353bB450c2CC0D91C681bf6102166A7465C9-->
                    <!--                    /collection?contract=0x5b97353bb450c2cc0d91c681bf6102166a7465c9-->
                    <router-link
                      class="text-dark text-bold icon-move-right"
                      :to="{
                        name: 'Collection',
                        query: {
                          contract: collection.contract,
                        },
                      }"
                      >{{ collection.name }}
                    </router-link>
                  </h5>
                  <p class="mb-0">
                    {{ collection.description.substring(0, 100) + "..." }}
                  </p>
                </div>
                <hr class="dark horizontal my-0" />
                <div class="card-footer d-flex">
                  <a
                    type="button"
                    class="btn btn-lg btn-close-dark btn-icon-only me-1"
                    :href="collection.twitter"
                  >
                    <span class="btn-inner--icon"
                      ><i class="text-info fab fa-twitter"></i
                    ></span>
                  </a>
                  <a
                    type="button"
                    class="btn btn-lg btn-close-dark btn-icon-only me-1"
                    :href="collection.discord"
                  >
                    <span class="btn-inner--icon justify-content-evenly"
                      ><i class="text-dark fab fa-discord"></i
                    ></span>
                  </a>
                  <a
                    type="button"
                    class="btn btn-lg btn-close-dark btn-icon-only"
                    :href="collection.website"
                  >
                    <span class="btn-inner--icon"
                      ><i class="text-primary fa fa-globe"></i
                    ></span>
                  </a>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <!--      <div class="row mt-5">-->
      <!--        <div class="col-lg-3 col-md-6 col-sm-6">-->
      <!--          <mini-cards-->
      <!--            title="Bookings"-->
      <!--            value="281"-->
      <!--            percentage="+55%"-->
      <!--            iconName="weekend"-->
      <!--            detail="than last week"-->
      <!--            iconClass="text-white"-->
      <!--            iconBackground="bg-gradient-dark"-->
      <!--          />-->
      <!--        </div>-->
      <!--        <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">-->
      <!--          <mini-cards-->
      <!--            title="Today's Users"-->
      <!--            value="2,300"-->
      <!--            percentage="+3%"-->
      <!--            iconName="leaderboard"-->
      <!--            detail="than last month"-->
      <!--            iconClass="text-white"-->
      <!--            iconBackground="bg-gradient-primary"-->
      <!--          />-->
      <!--        </div>-->
      <!--        <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">-->
      <!--          <mini-cards-->
      <!--            title="Revenue"-->
      <!--            value="34k"-->
      <!--            percentage="+1%"-->
      <!--            iconName="store"-->
      <!--            detail="than yesterday"-->
      <!--            iconClass="text-white"-->
      <!--            iconBackground="bg-gradient-success"-->
      <!--          />-->
      <!--        </div>-->
      <!--        <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">-->
      <!--          <mini-cards-->
      <!--            title="Followers"-->
      <!--            value="+91"-->
      <!--            iconName="person_add"-->
      <!--            detail="Just updated"-->
      <!--            iconClass="text-white"-->
      <!--            iconBackground="bg-gradient-info"-->
      <!--          />-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <div class="row"></div>
  </div>
</template>
<script>
import "@/assets/js/world.js";

import Collections from "../contractToCollectionMap";

export default {
  name: "dashboard-default",
  data() {
    return {
      searchQuery: null,
      collections: Collections,
    };
  },
  components: {},
  computed: {
    filteredCollections() {
      const collections = [];
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.collections)) {
        collections.push(value);
      }
      if (this.searchQuery) {
        return collections.filter((item) => {
          const results = this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => item.name.toLowerCase().includes(v));
          if (results.length < 1) {
            return collections;
          } else {
            return results;
          }
        });
      } else {
        return collections;
      }
    },
  },
  mounted() {},
};
</script>

<style>
.row-whith-wide-cards .card {
  width: 100%;
}
</style>
