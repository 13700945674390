<template>
  <div class="container-fluid">
    <div class="page-header min-height-300 border-radius-xl mt-4">
      <img
        :src="selectedCollection.banner"
        alt="profile_image"
        class="shadow-sm w-100 border-radius-lg"
        width="1400"
      />
      <span class="mask bg-transparent opacity-6"></span>
    </div>
    <div class="card card-body mx-3 mx-md-4 mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <img
              :src="selectedCollection.img"
              alt="profile_image"
              class="shadow-sm w-100 border-radius-lg"
            />
          </div>
          <span class="mask bg-transparent opacity-6"></span>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">{{ selectedCollection.name }}</h5>
            <p class="mb-0 font-weight-normal text-sm">
              Owner:
              <a
                v-bind:href="
                  'https://etherscan.io/address/' + selectedCollection.owner
                "
                class="text-info"
              >
                {{ getEllipsisTxt(selectedCollection.owner, 5) }}
              </a>
            </p>
          </div>
        </div>
        <div
          class="col-lg-8 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3"
        >
          <div class="nav-wrapper position-relative end-0">
            <ul class="p-1 nav nav-pills nav-fill" role="tablist">
              <li class="nav-item">
                <h5 class="mb-1">
                  {{ this.floor == null ? 0 : this.floor }}
                  <span class="text-sm">$WRLD</span>
                </h5>
                <p class="mb-0 font-weight-normal text-sm">Floor</p>
              </li>
              <li class="nav-item">
                <h5 class="mb-0">
                  {{ this.supply }}
                </h5>
                <p class="mb-0 font-weight-normal text-sm">Supply</p>
              </li>
              <li class="nav-item">
                <h5 class="mb-0">
                  {{ this.volume }} <span class="text-sm">$WRLD</span>
                </h5>
                <p class="mb-0 font-weight-normal text-sm">Volume</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <section class="py-3">
      <div class="row mb-4 mb-md-0">
        <div class="col-md-12 me-auto my-auto text-center">
          <h5>Description</h5>
          <p>
            {{ this.selectedCollection.description }}
          </p>
        </div>
        <div class="col-lg-12 col-md-12 my-auto text-center">
          <!--          <h6 class="text-sm">Creator Royalties</h6>-->
          <!--          <p>0%</p>-->
          <p class="text-sm text-bold mb-4">
            Creator Royalties: {{ this.selectedCollection.royalties / 10 }}%
            <!--            <b class="text-dark ms-2 font-weight-bold">0%</b>-->
          </p>
        </div>
        <div class="col-lg-4 col-md-12 my-auto text-center"></div>
        <div class="col-lg-4 col-md-12 my-auto text-center">
          <div class="input-group input-group-outline">
            <input
              placeholder="Search"
              type="text"
              class="form-control b text-bold text-dark"
              v-model="searchQuery"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-12 my-auto text-center"></div>
      </div>
      <div class="mt-2 row mt-lg-4">
        <template v-if="filteredListings.length > 0">
          <template v-for="listing in filteredListings" :key="listing.token_id">
            <div class="mt-4 mb-3 col-lg-4 col-md-6">
              <div class="card">
                <div
                  class="card-header p-0 mx-3 mt-3 position-relative z-index-1 align-items-center justify-content-between"
                >
                  <a class="d-block border-radius-xl">
                    <template v-if="listing.metadata">
                      <template v-if="listing.metadata.image.includes('<svg')">
                        <!--                        {{ listing.metadata.image }}-->
                        <div
                          class="img-fluid border-radius-xl"
                          v-html="listing.metadata.image"
                        />
                      </template>
                      <template v-else>
                        <img
                          :src="listing.metadata.image"
                          alt="nft-image"
                          class="img-fluid border-radius-xl"
                          width="500"
                          height="500"
                        />
                      </template>
                    </template>
                    <template v-else>
                      <img
                        src="@/assets/img/logo-no-metadata.png"
                        alt="img-blur-shadow"
                        class="img-fluid border-radius-xl"
                        width="500"
                        height="500"
                      />
                    </template>
                  </a>
                </div>
                <div class="card-body pt-2">
                  <p class="mb-0 text-sm" v-if="listing.token_id">
                    #{{
                      listing.token_id.length >= 6 ? "N/A" : listing.token_id
                    }}
                  </p>
                  <h5 class="text-bold">
                    {{ listing.amount }}
                    <img
                      src="@/assets/img/wrld-token.png"
                      alt="wrld"
                      class="img-fluid border-radius-xl"
                      width="20"
                      height="20"
                    />
                    <span class="mb-1 text-sm">
                      (${{
                        getUsdPriceEqv(listing.amount, this.wrldTokenPrice)
                      }})</span
                    >
                  </h5>
                  <div class="accordion-item mb-3">
                    <h5 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button border-bottom font-weight-bold"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="'#id' + listing.token_id"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Properties
                        <i
                          class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3"
                          aria-hidden="true"
                        ></i>
                        <i
                          class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </h5>
                    <div
                      :id="'id' + listing.token_id"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionRental"
                    >
                      <div class="accordion-body text-sm opacity-8">
                        <template v-if="listing.metadata">
                          <template
                            v-for="attr in listing.metadata.attributes"
                            :key="attr.id"
                          >
                            <button
                              type="button"
                              class="btn btn-sm btn-outline-info mx-1"
                            >
                              <span class="text-info">{{
                                attr.trait_type
                              }}</span>
                              <span class="badge badge-primary">{{
                                attr.value
                              }}</span>
                            </button>
                          </template>
                        </template>
                        <template v-else>
                          No properties found for this NFT :(
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <template v-if="isOwner(listing)">
                      <a
                        href="javascript:;"
                        class="text-bold text-dark"
                        disabled=""
                      >
                        Owned By You
                      </a>
                    </template>
                    <template v-else>
                      <a
                        data-bs-toggle="modal"
                        data-bs-target="#buyingModal"
                        href="javascript:;"
                        class="text-bold text-success"
                        v-on:click="setListing(listing)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-plus-circle"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                          />
                          <path
                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                          />
                        </svg>
                        Buy now
                      </a>
                    </template>
                    <router-link
                      class="text-info icon-move-right"
                      :to="{
                        name: 'NftDetails',
                        query: {
                          token_address: listing.token_address,
                          token_id: listing.token_id,
                        },
                      }"
                      >Details
                      <i
                        class="fas fa-arrow-right text-xs ms-1"
                        aria-hidden="true"
                      ></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="mt-4 mb-3 col-lg-12 col-md-6">
            <div class="text-center">
              <p>No Listings</p>
            </div>
          </div>
        </template>
      </div>
    </section>
  </div>

  <div
    class="modal fade"
    id="buyingModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="buyingModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="buyingModalLabel">Buy Now</h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="buyingModalClose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 mb-4 text-center">
              <i class="material-icons-round opacity-10 fs-1">store</i>
              <p class="text-sm">
                Review this information to ensure it’s what you want to buy.
              </p>
              <div v-if="selectedListing">
                <h6>
                  {{ this.selectedCollection.name }}
                </h6>
                <p class="text-sm mb-0">
                  Token Id:
                  <b
                    class="text-dark ms-2 font-weight-bold"
                    v-if="this.selectedListing.token_id"
                    >{{
                      this.selectedListing.token_id.length >= 6
                        ? "N/A"
                        : this.selectedListing.token_id
                    }}</b
                  >
                </p>
                <p class="text-sm mb-0">
                  Blockchain:
                  <span class="text-dark ms-2 font-weight-bold">Ethereum</span>
                </p>
                <p class="text-sm">
                  Contract:
                  <a
                    v-bind:href="
                      'https://etherscan.io/address/' +
                      this.selectedListing.token_address
                    "
                  >
                    <span class="text-info ms-2 font-weight-bold">
                      {{
                        getEllipsisTxt(this.selectedListing.token_address, 5)
                      }}</span
                    >
                  </a>
                </p>
              </div>
            </div>
            <div class="col-12">
              <div class="table-responsive">
                <table class="table text-right">
                  <thead class="bg-default">
                    <tr>
                      <th scope="col" class="pe-2 text-start ps-2">Item</th>
                      <th scope="col" class="pe-2">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="selectedListing">
                      <td
                        class="text-start"
                        v-if="this.selectedListing.token_id"
                      >
                        Token Id:
                        {{
                          this.selectedListing.token_id.length >= 6
                            ? "N/A"
                            : this.selectedListing.token_id
                        }}
                      </td>
                      <td class="ps-4">
                        {{ this.selectedListing.amount }} $WRLD
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th class="h5 ps-4">Total</th>
                      <th
                        colspan="1"
                        class="text-right h5 ps-4"
                        v-if="selectedListing"
                      >
                        {{ this.selectedListing.amount }} $WRLD
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <template v-if="getApprovalInProgress">
            <button class="btn bg-gradient-success" type="button" disabled>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Buy In Progress...
            </button>
          </template>
          <template v-else>
            <button
              type="button"
              class="btn bg-gradient-success"
              v-on:click="buyNow(this.selectedListing)"
            >
              Buy
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEllipsisTxt } from "@/helpers/formatters";
import useMoralis from "../composables/useMoralis";
import contractToCollectionsMap from "@/contractToCollectionMap";
import router from "@/router";
import Moralis from "moralis";

export default {
  name: "collection",
  setup() {
    const {
      authenticate,
      logout,
      isAuthenticated,
      isAuthenticating,
      user,
    } = useMoralis();
    return {
      getEllipsisTxt,
      authenticate,
      logout,
      isAuthenticated,
      isAuthenticating,
      user,
    };
  },
  data() {
    return {
      showMenu: false,
      contractToCollectionsMap,
      selectedCollection: {
        name: null,
      },
      listings: [],
      floor: null,
      owners: 0,
      volume: 0,
      supply: 0,
      selectedListing: null,
      approved: false,
      approveInProgress: false,
      wrldTokenPrice: 0,
      searchQuery: null,
    };
  },
  components: {},
  computed: {
    filteredListings() {
      function compare(a, b) {
        if (parseInt(a.amount) < parseInt(b.amount)) return -1;
        if (parseInt(a.amount) > parseInt(b.amount)) return 1;
        return 0;
      }

      const listing = [];
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.listings)) {
        listing.push(value);
      }
      if (this.searchQuery) {
        const results = [];
        listing.filter((item) => {
          if (item.metadata.image.includes("<svg")) {
            var doc = new DOMParser().parseFromString(
              item.metadata.image.toLowerCase(),
              "text/xml"
            );
            const name = doc.getElementById("domaintext1").innerHTML;
            if (name === this.searchQuery) {
              results.push(item);
            }
          } else {
            console.log(item.token_id);
            console.log(this.searchQuery);
            if (item.token_id.toString().includes(this.searchQuery)) {
              results.push(item);
              return results;
            }
          }
          // eslint-disable-next-line no-unused-vars
          for (const attr in item.metadata.attributes) {
            var split = this.searchQuery.toLowerCase().split(" ");
            var found = false;
            for (const i in split) {
              if (
                item.metadata.attributes[attr]["value"]
                  .toLowerCase()
                  .includes(split[i])
              ) {
                results.push(item);
                found = true;
                break;
              }
            }
            if (found) {
              break;
            }
          }
        });
        return results.sort(compare);
      } else {
        return listing.sort(compare);
      }
    },
    getApproval() {
      return this.approved;
    },
    getApprovalInProgress() {
      return this.approveInProgress;
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    const self = this;
    Moralis.Web3API.token
      .getTokenPrice({
        address: "0xD5d86FC8d5C0Ea1aC1Ac5Dfab6E529c9967a45E9",
        chain: "0x1",
        exchange: "uniswap-v3",
      })
      .then((result) => {
        self.wrldTokenPrice = result.usdPrice;
      });
    if (this.$route.query.contract) {
      const token_address = this.$route.query.contract;
      this.selectedCollection = this.contractToCollectionsMap[token_address];
      // pull listing
      const Listing = Moralis.Object.extend("Listing");
      const query = new Moralis.Query(Listing);
      query.equalTo("token_address", token_address.toLowerCase());
      query.equalTo("buyer", null);
      query.limit(1000);
      query.find().then((listings) => {
        for (let i = 0; i < listings.length; i++) {
          if (self.floor == null) {
            self.floor = parseInt(listings[i].attributes.amount);
          } else {
            if (self.floor > parseInt(listings[i].attributes.amount)) {
              self.floor = parseInt(listings[i].attributes.amount);
            }
          }
          if (listings[i].attributes.metadata) {
            if (listings[i].attributes.metadata.image) {
              if (listings[i].attributes.metadata.image.startsWith("ipfs")) {
                listings[i].attributes.metadata.image =
                  "https://ipfs.moralis.io:2053/ipfs/" +
                  listings[i].attributes.metadata.image
                    .split("ipfs://")
                    .slice(-1)[0];
              } else {
                if (listings[i].attributes.metadata.image.includes("<svg")) {
                  const regex = new RegExp("width='\\d+' height='\\d+'");
                  listings[i].attributes.metadata.image = listings[
                    i
                  ].attributes.metadata.image.replace(
                    regex,
                    "width='100%' height='100%'"
                  );
                }
              }
            }
          }
          self.listings.push(listings[i].attributes);
        }
      });

      const Secondquery = new Moralis.Query(Listing);
      Secondquery.equalTo("token_address", token_address.toLowerCase());
      Secondquery.notEqualTo("buyer", null);
      Secondquery.find().then((listings) => {
        for (let i = 0; i < listings.length; i++) {
          self.volume = self.volume + parseInt(listings[i].attributes.amount);
        }
      });
      const baseURI = "https://api.etherscan.io/api";
      this.$http
        .get(baseURI, {
          params: {
            module: "stats",
            action: "tokensupply",
            contractaddress: this.$route.query.contract,
            apikey: "4QT27R53PB7M6R2CSYMJRGTX68Y9J19KRJ",
          },
        })
        .then((data) => {
          if (data.data.result === "0") {
            self.supply = this.selectedCollection.tokenSupply;
          } else {
            self.supply = data.data.result;
          }
        });
    } else {
      router.push({ name: "Marketplace" });
    }
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async buyNow(listing) {
      const self = this;
      self.approveInProgress = true;
      const seller = listing.seller;
      const hostContract = listing.token_address;
      const price = Moralis.Units.ETH(listing.amount);
      const tokenId = listing.token_id;
      const creatorPayout = listing.creator_payout;
      const nounce = listing.nounce;
      try {
        const sig = listing.sig;
        const abi = [
          {
            inputs: [],
            stateMutability: "nonpayable",
            type: "constructor",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "spender",
                type: "address",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "value",
                type: "uint256",
              },
            ],
            name: "Approval",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
              },
            ],
            name: "OwnershipTransferred",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "from",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "to",
                type: "address",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "value",
                type: "uint256",
              },
            ],
            name: "Transfer",
            type: "event",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "owner",
                type: "address",
              },
              {
                internalType: "address",
                name: "spender",
                type: "address",
              },
            ],
            name: "allowance",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "spender",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            name: "approve",
            outputs: [
              {
                internalType: "bool",
                name: "",
                type: "bool",
              },
            ],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "account",
                type: "address",
              },
            ],
            name: "balanceOf",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "cap",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
              },
              {
                internalType: "uint8",
                name: "_claimNonce",
                type: "uint8",
              },
              {
                internalType: "bytes",
                name: "_signature",
                type: "bytes",
              },
            ],
            name: "claim",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "claimEnabled",
            outputs: [
              {
                internalType: "bool",
                name: "",
                type: "bool",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "decimals",
            outputs: [
              {
                internalType: "uint8",
                name: "",
                type: "uint8",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "spender",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "subtractedValue",
                type: "uint256",
              },
            ],
            name: "decreaseAllowance",
            outputs: [
              {
                internalType: "bool",
                name: "",
                type: "bool",
              },
            ],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "enableSecondClaim",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "spender",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "addedValue",
                type: "uint256",
              },
            ],
            name: "increaseAllowance",
            outputs: [
              {
                internalType: "bool",
                name: "",
                type: "bool",
              },
            ],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "to",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            name: "mint",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "name",
            outputs: [
              {
                internalType: "string",
                name: "",
                type: "string",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "owner",
            outputs: [
              {
                internalType: "address",
                name: "",
                type: "address",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "renounceOwnership",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "symbol",
            outputs: [
              {
                internalType: "string",
                name: "",
                type: "string",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "bool",
                name: "_claimEnabled",
                type: "bool",
              },
            ],
            name: "toggleClaim",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "totalSupply",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "to",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            name: "transfer",
            outputs: [
              {
                internalType: "bool",
                name: "",
                type: "bool",
              },
            ],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "from",
                type: "address",
              },
              {
                internalType: "address",
                name: "to",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            name: "transferFrom",
            outputs: [
              {
                internalType: "bool",
                name: "",
                type: "bool",
              },
            ],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "newOwner",
                type: "address",
              },
            ],
            name: "transferOwnership",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
        ];
        const WRLDTokenContract = "0xD5d86FC8d5C0Ea1aC1Ac5Dfab6E529c9967a45E9";
        const options = {
          chain: "0x1",
          contractAddress: WRLDTokenContract,
          functionName: "approve",
          msgValue: 0,
          abi: abi,
          params: {
            spender: "0x22B03D85eeff6F5F0157c9B3981Ca2E5dE031922",
            amount: price,
          },
        };
        if (!self.approved) {
          const approval = await Moralis.executeFunction(options);
          await approval.wait();
          self.approved = true;
        }
        const marketPlaceAbi = [
          {
            inputs: [
              {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "_nounce",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "_signature",
                type: "bytes",
              },
            ],
            name: "claim",
            outputs: [],
            stateMutability: "payable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "_wrldToken",
                type: "address",
              },
            ],
            stateMutability: "nonpayable",
            type: "constructor",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "seller",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "buyer",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "hostContract",
                type: "address",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "price",
                type: "uint256",
              },
            ],
            name: "ListingPurchased",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
              },
            ],
            name: "OwnershipTransferred",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "to",
                type: "address",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            name: "kickbackClaimed",
            type: "event",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "_contract",
                type: "address",
              },
              {
                internalType: "address",
                name: "_wallet",
                type: "address",
              },
            ],
            name: "ownerSetCreatorWallet",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "_seller",
                type: "address",
              },
              {
                internalType: "address",
                name: "_hostContract",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "_price",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "_tokenId",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "nounce",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "_creatorPercent",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "_signature",
                type: "bytes",
              },
            ],
            name: "purchase",
            outputs: [],
            stateMutability: "payable",
            type: "function",
          },
          {
            inputs: [],
            name: "renounceOwnership",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "uint16",
                name: "_percent",
                type: "uint16",
              },
            ],
            name: "setCommunityPercent",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "_contract",
                type: "address",
              },
              {
                internalType: "address",
                name: "_wallet",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "_nounce",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "_signature",
                type: "bytes",
              },
            ],
            name: "setCreatorWallet",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "_wallet",
                type: "address",
              },
            ],
            name: "setPayoutWallet",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "newOwner",
                type: "address",
              },
            ],
            name: "transferOwnership",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "withdraw",
            outputs: [],
            stateMutability: "payable",
            type: "function",
          },
          {
            inputs: [],
            name: "communityPercent",
            outputs: [
              {
                internalType: "uint16",
                name: "",
                type: "uint16",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "_contract",
                type: "address",
              },
            ],
            name: "getCreatorWallet",
            outputs: [
              {
                internalType: "address",
                name: "",
                type: "address",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "owner",
            outputs: [
              {
                internalType: "address",
                name: "",
                type: "address",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "payoutWallet",
            outputs: [
              {
                internalType: "address",
                name: "",
                type: "address",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "wrld",
            outputs: [
              {
                internalType: "contract kWRLD_Token_Ethereum",
                name: "",
                type: "address",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
        ];
        const purchaseOptions = {
          chain: "0x1",
          contractAddress: "0x22B03D85eeff6F5F0157c9B3981Ca2E5dE031922",
          functionName: "purchase",
          abi: marketPlaceAbi,
          params: {
            _seller: seller,
            _hostContract: hostContract,
            _price: price,
            _tokenId: tokenId,
            nounce: nounce,
            _creatorPercent: creatorPayout,
            _signature: sig,
          },
        };
        const purchase = await Moralis.executeFunction(purchaseOptions);
        await purchase.wait();
        self.approveInProgress = false;
        self.approved = false;
        self.selectedListing = null;
        document.getElementById("buyingModalClose").click();
        this.$notify({
          title: "Congrats on your purchase!",
          text: "https://etherscan.io/tx/" + purchase.hash,
          type: "success",
        });
      } catch (e) {
        console.log(e);
        this.$notify({
          title: "Error with purchase",
          text: e.message,
          type: "error",
        });
      }
    },
    setListing(listing) {
      this.selectedListing = listing;
    },
    isOwner(listing) {
      if (Moralis.User.current()) {
        return listing.seller == Moralis.User.current().get("ethAddress");
      } else {
        return false;
      }
    },
    getUsdPriceEqv(listingPrice, wrldPrice) {
      return (parseFloat(listingPrice) * parseFloat(wrldPrice)).toFixed(2);
    },
  },
};
</script>
