<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <div class="card mb-4">
          <div class="card-header p-3 pb-0">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <template v-if="supportedCollection">
                  <router-link
                    class="text-info icon-move-right"
                    :to="{
                      name: 'Collection',
                      query: {
                        contract: fetched_data.token_address,
                      },
                    }"
                  >
                    <h5>
                      {{ fetched_data.name }}
                    </h5>
                  </router-link>
                </template>
                <template v-else>
                  <h5>
                    {{ fetched_data.name }}
                  </h5>
                </template>

                <p class="text-sm mb-0">
                  Token Id:
                  <b
                    class="text-dark ms-2 font-weight-bold"
                    v-if="fetched_data.token_id"
                    >{{
                      fetched_data.token_id.length >= 5
                        ? "N/A"
                        : fetched_data.token_id
                    }}</b
                  >
                </p>
                <p class="text-sm mb-0">
                  Blockchain:
                  <span class="text-dark ms-2 font-weight-bold">Ethereum</span>
                </p>
                <p class="text-sm">
                  Contract:
                  <a
                    v-bind:href="
                      'https://etherscan.io/address/' +
                      fetched_data.token_address
                    "
                  >
                    <span class="text-info ms-2 font-weight-bold">
                      {{ getEllipsisTxt(fetched_data.token_address, 5) }}</span
                    >
                  </a>
                </p>
              </div>
              <template v-if="isAuthenticated">
                <template v-if="owner_of_nft">
                  <template v-if="supportedCollection">
                    <template v-if="has_listing">
                      <button
                        href="javascript:;"
                        class="btn bg-gradient-danger icon-move-right"
                        @click="deleteListing(this.listing)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-plus-circle"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                          />
                          <path
                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                          />
                        </svg>
                        Delist
                      </button>
                    </template>
                    <template v-else>
                      <button
                        href="javascript:;"
                        class="btn bg-gradient-primary icon-move-right"
                        data-bs-toggle="modal"
                        data-bs-target="#listingModal"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-plus-circle"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                          />
                          <path
                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                          />
                        </svg>
                        Sell
                      </button>
                    </template>
                  </template>
                  <template v-else>
                    <button
                      href="javascript:;"
                      class="btn bg-gradient-secondary icon-move-right"
                      disabled
                    >
                      Listing Not Supported
                    </button>
                  </template>
                </template>
                <template v-else>
                  <template v-if="has_listing">
                    <template v-if="buyApproveInProgress">
                      <button
                        class="btn bg-gradient-success icon-move-right"
                        type="button"
                        disabled
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Buy In Progress...
                      </button>
                    </template>
                    <template v-else>
                      <button
                        href="javascript:;"
                        class="btn bg-gradient-success icon-move-right"
                        v-on:click="buyNow(this.listing[0].attributes)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-plus-circle"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                          />
                          <path
                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                          />
                        </svg>
                        Buy
                      </button>
                    </template>
                  </template>
                  <template v-else>
                    <button
                      href="javascript:;"
                      class="btn bg-gradient-success icon-move-right"
                    >
                      Not for Sale
                    </button>
                  </template>
                </template>
              </template>
              <template v-else>
                <p class="text-sm mb-0">
                  Connect Wallet to be able to buy or sell this NFT.
                </p>
              </template>
            </div>
          </div>
          <div class="card-body p-3 pt-0">
            <hr class="horizontal dark mt-0 mb-4" />
            <div class="row">
              <div class="col-lg-6 col-md-6 col-12">
                <div class="d-flex">
                  <div>
                    <template v-if="fetched_data.image">
                      <template v-if="fetched_data.image.includes('<svg')">
                        <div
                          class="img-fluid border-radius-xl"
                          v-html="fetched_data.image"
                        />
                      </template>
                      <template v-else>
                        <img
                          :src="fetched_data.image"
                          alt="nft-image"
                          class="img-fluid border-radius-xl"
                          width="500"
                          height="500"
                        />
                      </template>
                    </template>
                    <template v-else>
                      <img
                        src="@/assets/img/logo-no-metadata.png"
                        alt="img-blur-shadow"
                        class="img-fluid border-radius-xl"
                        width="500"
                        height="500"
                      />
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12 my-auto">
                <div class="d-flex mx-1 mb-3">
                  <div class="mb-3">
                    <div>
                      <h6 class="mb-0">
                        Owner:
                        <a
                          class="text-info"
                          v-bind:href="
                            'https://etherscan.io/address/' +
                            fetched_data.owner_of
                          "
                        >
                          {{ getEllipsisTxt(fetched_data.owner_of, 5) }}
                        </a>
                      </h6>
                      <h6 class="mb-0">
                        Status:
                        <span class="badge badge-sm bg-gradient-dark">{{
                          has_listing ? "Listed" : "Unlisted"
                        }}</span>
                      </h6>
                      <h6 class="mb-0">
                        Refresh Metadata:
                        <a
                          v-on:click="
                            refreshMetadata(
                              fetched_data.token_address,
                              fetched_data.token_id
                            )
                          "
                          href="javascript:;"
                          class="text-info icon-move-right"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-arrow-repeat"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"
                            />
                            <path
                              fill-rule="evenodd"
                              d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                            />
                          </svg>
                        </a>
                      </h6>
                      <template v-if="has_listing">
                        <h6 class="mb-0">
                          Price:
                          <b class="text-info mx-1">
                            {{ listing[0].get("amount") }}
                          </b>
                          <img
                            src="@/assets/img/wrld-token.png"
                            alt="wrld"
                            class="img-fluid border-radius-xl"
                            width="20"
                            height="20"
                          />
                          <span class="mb-1 text-sm">
                            (${{
                              getUsdPriceEqv(
                                listing[0].get("amount"),
                                this.wrldTokenPrice
                              )
                            }})</span
                          >
                        </h6>
                      </template>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="accordion-1">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-12 mx-auto">
                          <div class="accordion" id="accordionRental">
                            <div class="accordion-item mb-3">
                              <h5 class="accordion-header" id="headingOne">
                                <button
                                  class="accordion-button border-bottom font-weight-bold collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-expanded="false"
                                  aria-controls="collapseOne"
                                >
                                  Description
                                  <i
                                    class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </h5>
                              <div
                                id="collapseOne"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionRental"
                                style=""
                              >
                                <div class="accordion-body text-sm opacity-8">
                                  <template v-if="fetched_data.metadata">
                                    {{ fetched_data.metadata.description }}
                                  </template>
                                  <template v-else>
                                    No description found for this NFT :(
                                  </template>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item mb-3">
                              <h5 class="accordion-header" id="headingTwo">
                                <button
                                  class="accordion-button border-bottom font-weight-bold"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  Properties
                                  <i
                                    class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </h5>
                              <div
                                id="collapseTwo"
                                class="accordion-collapse collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionRental"
                              >
                                <div class="accordion-body text-sm opacity-8">
                                  <template v-if="fetched_data.attributes">
                                    <template
                                      v-for="attr in fetched_data.attributes"
                                      :key="attr.id"
                                    >
                                      <button
                                        type="button"
                                        class="btn btn-outline-info mx-1"
                                      >
                                        <span class="text-info">{{
                                          attr.trait_type
                                        }}</span>
                                        <span class="badge badge-primary">{{
                                          attr.value
                                        }}</span>
                                      </button>
                                    </template>
                                  </template>
                                  <template v-else>
                                    No properties found for this NFT :(
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="horizontal dark mt-4 mb-4" />
            <div class="row">
              <!--                <h6 class="mb-3 mt-4">Billing Information</h6>-->
              <!--                <ul class="list-group">-->
              <!--                  <li-->
              <!--                    class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"-->
              <!--                  >-->
              <!--                    <div class="d-flex flex-column">-->
              <!--                      <h6 class="mb-3 text-sm">Oliver Liam</h6>-->
              <!--                      <span class="mb-2 text-xs">-->
              <!--                        Company Name:-->
              <!--                        <span class="text-dark font-weight-bold ms-2"-->
              <!--                          >Viking Burrito</span-->
              <!--                        >-->
              <!--                      </span>-->
              <!--                      <span class="mb-2 text-xs">-->
              <!--                        Email Address:-->
              <!--                        <span class="text-dark ms-2 font-weight-bold"-->
              <!--                          >oliver@burrito.com</span-->
              <!--                        >-->
              <!--                      </span>-->
              <!--                      <span class="text-xs">-->
              <!--                        VAT Number:-->
              <!--                        <span class="text-dark ms-2 font-weight-bold"-->
              <!--                          >FRB1235476</span-->
              <!--                        >-->
              <!--                      </span>-->
              <!--                    </div>-->
              <!--                  </li>-->
              <!--                </ul>-->
              <!--              <div class="col-lg-3 col-md-6 col-12">-->
              <!--                <h6 class="mb-3">Track order</h6>-->
              <!--                <div class="timeline timeline-one-side">-->
              <!--                  <div class="timeline-block mb-3">-->
              <!--                    <span class="timeline-step">-->
              <!--                      <i class="material-icons text-secondary text-lg"-->
              <!--                        >notifications</i-->
              <!--                      >-->
              <!--                    </span>-->
              <!--                    <div class="timeline-content">-->
              <!--                      <h6 class="text-dark text-sm font-weight-bold mb-0">-->
              <!--                        Order received-->
              <!--                      </h6>-->
              <!--                      <p-->
              <!--                        class="text-secondary font-weight-bold text-xs mt-1 mb-0"-->
              <!--                      >-->
              <!--                        22 DEC 7:20 AM-->
              <!--                      </p>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                  <div class="timeline-block mb-3">-->
              <!--                    <span class="timeline-step">-->
              <!--                      <i class="material-icons text-secondary text-lg">code</i>-->
              <!--                    </span>-->
              <!--                    <div class="timeline-content">-->
              <!--                      <h6 class="text-dark text-sm font-weight-bold mb-0">-->
              <!--                        Generate order id #1832412-->
              <!--                      </h6>-->
              <!--                      <p-->
              <!--                        class="text-secondary font-weight-bold text-xs mt-1 mb-0"-->
              <!--                      >-->
              <!--                        22 DEC 7:21 AM-->
              <!--                      </p>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                  <div class="timeline-block mb-3">-->
              <!--                    <span class="timeline-step">-->
              <!--                      <i class="material-icons text-secondary text-lg"-->
              <!--                        >shopping_cart</i-->
              <!--                      >-->
              <!--                    </span>-->
              <!--                    <div class="timeline-content">-->
              <!--                      <h6 class="text-dark text-sm font-weight-bold mb-0">-->
              <!--                        Order transmited to courier-->
              <!--                      </h6>-->
              <!--                      <p-->
              <!--                        class="text-secondary font-weight-bold text-xs mt-1 mb-0"-->
              <!--                      >-->
              <!--                        22 DEC 8:10 AM-->
              <!--                      </p>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                  <div class="timeline-block mb-3">-->
              <!--                    <span class="timeline-step">-->
              <!--                      <i-->
              <!--                        class="material-icons text-success text-gradient text-lg"-->
              <!--                        >done</i-->
              <!--                      >-->
              <!--                    </span>-->
              <!--                    <div class="timeline-content">-->
              <!--                      <h6 class="text-dark text-sm font-weight-bold mb-0">-->
              <!--                        Order delivered-->
              <!--                      </h6>-->
              <!--                      <p-->
              <!--                        class="text-secondary font-weight-bold text-xs mt-1 mb-0"-->
              <!--                      >-->
              <!--                        22 DEC 4:54 PM-->
              <!--                      </p>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="col-lg-12 col-12 mb-3 ms-auto">
                <div class="card">
                  <div class="table-responsive">
                    <table
                      class="table text-center text-dark align-items-center mb-0"
                    >
                      <thead>
                        <tr>
                          <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            Type
                          </th>
                          <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                          >
                            From
                          </th>
                          <th
                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                          >
                            To
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="nftTransfers">
                          <template
                            v-for="trans in nftTransfers"
                            :key="trans.id"
                          >
                            <tr>
                              <td class="align-middle text-center text-sm">
                                <span class="badge badge-sm badge-success">{{
                                  trans.from_address ===
                                  "0x0000000000000000000000000000000000000000"
                                    ? "Mint"
                                    : "Transfer"
                                }}</span>
                              </td>
                              <td class="align-middle text-center">
                                <a
                                  v-bind:href="
                                    'https://etherscan.io/address/' +
                                    trans.from_address
                                  "
                                >
                                  <p class="text-xs font-weight-bold mb-0">
                                    {{
                                      trans.from_address ===
                                      "0x0000000000000000000000000000000000000000"
                                        ? "Null Address"
                                        : getEllipsisTxt(trans.from_address, 5)
                                    }}
                                  </p>
                                </a>
                              </td>
                              <td>
                                <a
                                  v-bind:href="
                                    'https://etherscan.io/address/' +
                                    trans.to_address
                                  "
                                >
                                  <p class="text-xs font-weight-bold mb-0">
                                    {{ getEllipsisTxt(trans.to_address, 5) }}
                                  </p>
                                </a>
                              </td>
                            </tr>
                          </template>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="listingModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="listingModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="listingModalLabel">List item for sale</h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="ListingModalClose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form ref="listingForm">
            <div class="row">
              <div class="col-md-12 mb-4">
                <h5 id="listingModalHeader">
                  {{ fetched_data.name }}
                </h5>
                <div class="d-flex flex-column mb-3">
                  <span class="mb-2 text-xs">
                    Token Id:
                    <span
                      class="text-dark ms-sm-2"
                      v-if="fetched_data.token_id"
                      >{{
                        fetched_data.token_id.length >= 5
                          ? "N/A"
                          : fetched_data.token_id
                      }}</span
                    ></span
                  >
                  <span class="mb-2 text-xs">
                    Contract:
                    <a
                      v-bind:href="
                        'https://etherscan.io/address/' +
                        fetched_data.token_address
                      "
                    >
                      <span class="text-info ms-sm-2">
                        {{ fetched_data.token_address.substring(0, 5) }}</span
                      >
                    </a>
                  </span>
                  <span class="mb-2 text-xs">
                    Blockchain:
                    <span class="text-dark ms-sm-2">Ethereum</span></span
                  >
                  <span class="mb-2 text-xs">
                    Token Standard:
                    <span class="text-dark ms-sm-2">{{
                      fetched_data.contract_type
                    }}</span>
                  </span>
                </div>
                <template v-if="fetched_data.image">
                  <template v-if="fetched_data.image.includes('<svg')">
                    <div
                      class="img-fluid border-radius-xl"
                      v-html="fetched_data.image"
                    />
                  </template>
                  <template v-else>
                    <img
                      :src="fetched_data.image"
                      alt="nft-image"
                      class="img-fluid border-radius-xl"
                      width="500"
                      height="500"
                    />
                  </template>
                </template>
                <template v-else>
                  <img
                    src="@/assets/img/logo-no-metadata.png"
                    alt="img-blur-shadow"
                    class="img-fluid border-radius-xl"
                    width="500"
                    height="500"
                  />
                </template>
              </div>
              <div class="col-md-12 text-center">
                <span class="mb-2 text-xs text-danger">{{
                  listingFormError.message
                }}</span>
                <vmd-input
                  label="Sale Price (WRLD)"
                  placeholder=""
                  name="sale_amount"
                  v-bind:value="fetched_data.sale_amount"
                  v-bind:error="listingFormError.error"
                  type="number"
                />
                <p class="text-sm">
                  WRLD Price = ${{ parseFloat(this.wrldTokenPrice).toFixed(2) }}
                </p>
              </div>
              <hr class="mt-3" />
              <div class="d-flex flex-column">
                <h6 class="text-sm">Fees</h6>
                <span class="mb-2 text-xs"
                  >Listing is free. Once sold, the following fees will be
                  deducted.</span
                >
                <span class="mb-2 text-xs">
                  Service Fee:
                  <span class="text-dark ms-sm-2">5%</span></span
                >
                <span
                  class="mb-2 text-xs"
                  v-if="
                    this.contractToCollectionsMap[
                      this.fetched_data.token_address
                    ]
                  "
                >
                  Creator Fee:
                  <span
                    class="text-dark ms-sm-2"
                    v-if="this.fetched_data.token_address"
                    >{{
                      this.contractToCollectionsMap[
                        this.fetched_data.token_address
                      ].royalties / 10
                    }}%
                  </span></span
                >
              </div>
              <div class="row"></div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <template v-if="approvedForTransfer">
            <button
              type="button"
              class="btn bg-gradient-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn bg-gradient-success"
              v-on:click="addListing(fetched_data)"
            >
              Add Listing
            </button>
          </template>
          <template v-else>
            <template v-if="approvalLoading">
              <button
                class="btn btn-lg btn-outline-primary"
                type="button"
                disabled
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            </template>
            <template v-else>
              <button
                type="button"
                class="btn bg-gradient-success"
                v-on:click="approveTransfer(fetched_data)"
              >
                Approve
              </button>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEllipsisTxt } from "../helpers/formatters.js";
import useMoralis from "../composables/useMoralis";
import Moralis from "moralis";
import VmdInput from "./VmdInput";
import contractToCollectionsMap from "@/contractToCollectionMap";

export default {
  name: "nft-details",
  components: {
    VmdInput,
  },
  setup() {
    const {
      authenticate,
      logout,
      isAuthenticated,
      isAuthenticating,
      user,
    } = useMoralis();
    return {
      getEllipsisTxt,
      authenticate,
      logout,
      isAuthenticated,
      isAuthenticating,
      user,
    };
  },
  data() {
    return {
      token_id: null,
      token_address: null,
      fetched_data: {
        token_id: null,
        name: null,
        metadata: null,
        sale_amount: null,
        token_address: "",
        contract_type: null,
        image: null,
        owner_of: "",
        attributes: null,
      },
      owner_of_nft: false,
      listingFormError: {
        error: false,
        message: null,
      },
      nftTransfers: null,
      listing: null,
      has_listing: false,
      approved: false,
      approvalLoading: false,
      buyApproveInProgress: false,
      buyApproved: false,
      contractToCollectionsMap,
      supportedCollection: false,
      wrldTokenPrice: 0,
    };
  },
  mounted() {
    const self = this;
    Moralis.Web3API.token
      .getTokenPrice({
        address: "0xD5d86FC8d5C0Ea1aC1Ac5Dfab6E529c9967a45E9",
        chain: "0x1",
        exchange: "uniswap-v3",
      })
      .then((result) => {
        console.log(result);
        self.wrldTokenPrice = result.usdPrice;
      });
    this.token_id = this.$route.query.token_id;
    this.token_address = this.$route.query.token_address;
    const options = {
      address: this.token_address,
      token_id: this.token_id,
      chain: "0x1",
    };
    if (self.contractToCollectionsMap[self.token_address.toLowerCase()]) {
      self.supportedCollection = true;
    }
    // toke metadata
    Moralis.Web3API.token
      .getTokenIdMetadata(options)
      .then((data) => {
        self.fetched_data = {
          token_id: data.token_id,
          name: data.name,
          metadata: JSON.parse(data.metadata),
          sale_amount: null,
          token_address: data.token_address,
          contract_type: data.contract_type,
          owner_of: data.owner_of,
        };
        if (data.metadata != null) {
          self.fetched_data["attributes"] = JSON.parse(
            data.metadata
          ).attributes;
          if (JSON.parse(data.metadata).image != null) {
            if (JSON.parse(data.metadata).image.startsWith("ipfs")) {
              self.fetched_data["image"] =
                "https://ipfs.moralis.io:2053/ipfs/" +
                JSON.parse(data.metadata).image.split("ipfs://").slice(-1)[0];
            } else {
              self.fetched_data["image"] = JSON.parse(data.metadata).image;
              if (self.fetched_data["image"].includes("<svg")) {
                const regex = new RegExp("width='\\d+' height='\\d+'");
                self.fetched_data["image"] = self.fetched_data["image"].replace(
                  regex,
                  "width='100%' height='100%'"
                );
              }
            }
          }
        }
        self.isApproved(self.fetched_data);
        if (this.isAuthenticated) {
          const currentUser = Moralis.User.current();
          if (currentUser) {
            if (data.owner_of == currentUser.get("ethAddress")) {
              this.owner_of_nft = true;
            } else {
              this.owner_of_nft = false;
            }
          } else {
            this.owner_of_nft = false;
          }
        } else {
          this.owner_of_nft = false;
        }
      })
      .catch((e) => {
        console.log(e);
        this.$router.push("Profile");
      });
    // get transfers
    Moralis.Web3API.token.getWalletTokenIdTransfers(options).then((data) => {
      this.nftTransfers = data.result;
    });
    // pull listing
    const Listing = Moralis.Object.extend("Listing");
    const query = new Moralis.Query(Listing);
    query.equalTo("token_address", this.token_address);
    query.equalTo("token_id", this.token_id);
    query.equalTo("buyer", null);
    query.find().then((listing) => {
      if (listing.length > 1) {
        // ERROR too many listings.
        // Rerout back to profile to be safe
        alert("Error: Too many listings");
        this.$router.push("Profile");
      } else if (listing.length === 0) {
        this.listing = null;
        this.has_listing = false;
      } else if (listing.length === 1) {
        this.has_listing = true;
        this.listing = listing;
      }
    });
  },
  computed: {
    approvedForTransfer() {
      return this.approved;
    },
  },
  methods: {
    addListing(nft) {
      if (this.checkListingForm()) {
        const sale_amount = parseInt(this.fetched_data.sale_amount);
        const date = new Date();
        const params = {
          amount: sale_amount,
          seller: nft.owner_of,
          token_address: nft.token_address,
          token_id: nft.token_id,
          metadata: nft.metadata,
          listed_at: date,
          sold_at: null,
          buyer: null,
          creator_payout: this.contractToCollectionsMap[
            nft.token_address.toLowerCase()
          ].royalties,
        };
        if (this.approved) {
          Moralis.Cloud.run("addListing", params).then(
            (res) => {
              console.log(res);
              // Execute any logic that should take place after the object is saved.
              document.getElementById("ListingModalClose").click();
              // Reset sale amount
              this.$refs.listingForm.sale_amount.value = null;
              this.fetched_data.sale_amount = null;
              this.$notify({
                title: "Listing Added",
                text:
                  "Congratulations you have listed token #" +
                  nft.token_id.toString(),
                type: "success",
              });
              this.$router.go();
            },
            (error) => {
              // Execute any logic that should take place if the save fails.
              // error is a Moralis.Error with an error code and message.
              // Reset sale amount
              this.$refs.listingForm.sale_amount.value = null;
              this.fetched_data.sale_amount = null;
              alert("Failed to add listing, with error: " + error.message);
            }
          );
        }
      }
    },
    isApproved(nft) {
      const genericAbi = [
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "approved",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "Approval",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "operator",
              type: "address",
            },
            {
              indexed: false,
              internalType: "bool",
              name: "approved",
              type: "bool",
            },
          ],
          name: "ApprovalForAll",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "approve",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "getApproved",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              internalType: "address",
              name: "operator",
              type: "address",
            },
          ],
          name: "isApprovedForAll",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "operator",
              type: "address",
            },
            {
              internalType: "bool",
              name: "approved",
              type: "bool",
            },
          ],
          name: "setApprovalForAll",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ];
      const options = {
        chain: "0x1",
        address: nft.token_address,
        function_name: "isApprovedForAll",
        abi: genericAbi,
        params: {
          owner: Moralis.User.current().get("ethAddress"),
          operator: "0x22B03D85eeff6F5F0157c9B3981Ca2E5dE031922",
        },
      };
      Moralis.Web3API.native.runContractFunction(options).then((data) => {
        this.approved = data;
      });
    },
    approveTransfer(nft) {
      this.approvalLoading = true;
      const genericAbi = [
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "approved",
              type: "address",
            },
            {
              indexed: true,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "Approval",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "operator",
              type: "address",
            },
            {
              indexed: false,
              internalType: "bool",
              name: "approved",
              type: "bool",
            },
          ],
          name: "ApprovalForAll",
          type: "event",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "approve",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "getApproved",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              internalType: "address",
              name: "operator",
              type: "address",
            },
          ],
          name: "isApprovedForAll",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "operator",
              type: "address",
            },
            {
              internalType: "bool",
              name: "approved",
              type: "bool",
            },
          ],
          name: "setApprovalForAll",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ];
      const approvalOptions = {
        chain: "0x1",
        contractAddress: nft.token_address,
        functionName: "setApprovalForAll",
        abi: genericAbi,
        params: {
          operator: "0x22B03D85eeff6F5F0157c9B3981Ca2E5dE031922",
          approved: true,
        },
      };
      Moralis.executeFunction(approvalOptions).then(() => {
        this.approvalLoading = false;
        this.approved = true;
      });
    },
    refreshMetadata(address, id) {
      const options = {
        address: address,
        token_id: id,
        flag: "uri",
      };
      Moralis.Web3API.token.reSyncMetadata(options);
      this.$notify({
        title: "Refresh Requested",
        text: "We've queued this item for an update! Check back later...",
        type: "success",
      });
    },
    checkListingForm() {
      const sale_amount = this.$refs.listingForm.sale_amount.value;
      if (sale_amount) {
        if (sale_amount > 0) {
          this.fetched_data.sale_amount = sale_amount;
          return true;
        } else {
          this.listingFormError.error = true;
          this.listingFormError.message =
            "Specify a number amount greater than 0.";
          return false;
        }
      } else {
        this.listingFormError.error = true;
        this.listingFormError.message = "Specify a sale amount in WRLD.";
        return false;
      }
    },
    async deleteListing(listing) {
      const params = {
        objectId: listing[0].id,
        seller: listing[0].attributes.seller,
      };
      Moralis.Cloud.run("removeListing", params).then(
        () => {
          this.$notify({
            title: "Listing Removed",
            text:
              "Listing for token id #" +
              listing[0].attributes.token_id +
              " removed",
            type: "success",
          });
          this.$router.go();
        },
        (error) => {
          alert(error);
        }
      );
    },
    async buyNow(listing) {
      const self = this;
      self.buyApproveInProgress = true;
      const seller = listing.seller;
      const hostContract = listing.token_address;
      const price = Moralis.Units.ETH(listing.amount);
      const tokenId = listing.token_id;
      const creatorPayout = listing.creator_payout;
      const nounce = listing.nounce;
      try {
        const sig = listing.sig;
        const abi = [
          {
            inputs: [],
            stateMutability: "nonpayable",
            type: "constructor",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "spender",
                type: "address",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "value",
                type: "uint256",
              },
            ],
            name: "Approval",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
              },
            ],
            name: "OwnershipTransferred",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "from",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "to",
                type: "address",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "value",
                type: "uint256",
              },
            ],
            name: "Transfer",
            type: "event",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "owner",
                type: "address",
              },
              {
                internalType: "address",
                name: "spender",
                type: "address",
              },
            ],
            name: "allowance",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "spender",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            name: "approve",
            outputs: [
              {
                internalType: "bool",
                name: "",
                type: "bool",
              },
            ],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "account",
                type: "address",
              },
            ],
            name: "balanceOf",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "cap",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
              },
              {
                internalType: "uint8",
                name: "_claimNonce",
                type: "uint8",
              },
              {
                internalType: "bytes",
                name: "_signature",
                type: "bytes",
              },
            ],
            name: "claim",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "claimEnabled",
            outputs: [
              {
                internalType: "bool",
                name: "",
                type: "bool",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "decimals",
            outputs: [
              {
                internalType: "uint8",
                name: "",
                type: "uint8",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "spender",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "subtractedValue",
                type: "uint256",
              },
            ],
            name: "decreaseAllowance",
            outputs: [
              {
                internalType: "bool",
                name: "",
                type: "bool",
              },
            ],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "enableSecondClaim",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "spender",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "addedValue",
                type: "uint256",
              },
            ],
            name: "increaseAllowance",
            outputs: [
              {
                internalType: "bool",
                name: "",
                type: "bool",
              },
            ],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "to",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            name: "mint",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "name",
            outputs: [
              {
                internalType: "string",
                name: "",
                type: "string",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "owner",
            outputs: [
              {
                internalType: "address",
                name: "",
                type: "address",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "renounceOwnership",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "symbol",
            outputs: [
              {
                internalType: "string",
                name: "",
                type: "string",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "bool",
                name: "_claimEnabled",
                type: "bool",
              },
            ],
            name: "toggleClaim",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "totalSupply",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "to",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            name: "transfer",
            outputs: [
              {
                internalType: "bool",
                name: "",
                type: "bool",
              },
            ],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "from",
                type: "address",
              },
              {
                internalType: "address",
                name: "to",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            name: "transferFrom",
            outputs: [
              {
                internalType: "bool",
                name: "",
                type: "bool",
              },
            ],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "newOwner",
                type: "address",
              },
            ],
            name: "transferOwnership",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
        ];
        const WRLDTokenContract = "0xD5d86FC8d5C0Ea1aC1Ac5Dfab6E529c9967a45E9";
        const options = {
          chain: "0x1",
          contractAddress: WRLDTokenContract,
          functionName: "approve",
          msgValue: 0,
          abi: abi,
          params: {
            spender: "0x22B03D85eeff6F5F0157c9B3981Ca2E5dE031922",
            amount: price,
          },
        };
        if (!self.buyApproved) {
          const approval = await Moralis.executeFunction(options);
          await approval.wait();
          self.buyApproved = true;
        }
        const marketPlaceAbi = [
          {
            inputs: [
              {
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "_nounce",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "_signature",
                type: "bytes",
              },
            ],
            name: "claim",
            outputs: [],
            stateMutability: "payable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "_wrldToken",
                type: "address",
              },
            ],
            stateMutability: "nonpayable",
            type: "constructor",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "seller",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "buyer",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "hostContract",
                type: "address",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "price",
                type: "uint256",
              },
            ],
            name: "ListingPurchased",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
              },
              {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
              },
            ],
            name: "OwnershipTransferred",
            type: "event",
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: "address",
                name: "to",
                type: "address",
              },
              {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            name: "kickbackClaimed",
            type: "event",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "_contract",
                type: "address",
              },
              {
                internalType: "address",
                name: "_wallet",
                type: "address",
              },
            ],
            name: "ownerSetCreatorWallet",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "_seller",
                type: "address",
              },
              {
                internalType: "address",
                name: "_hostContract",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "_price",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "_tokenId",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "nounce",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "_creatorPercent",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "_signature",
                type: "bytes",
              },
            ],
            name: "purchase",
            outputs: [],
            stateMutability: "payable",
            type: "function",
          },
          {
            inputs: [],
            name: "renounceOwnership",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "uint16",
                name: "_percent",
                type: "uint16",
              },
            ],
            name: "setCommunityPercent",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "_contract",
                type: "address",
              },
              {
                internalType: "address",
                name: "_wallet",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "_nounce",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "_signature",
                type: "bytes",
              },
            ],
            name: "setCreatorWallet",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "_wallet",
                type: "address",
              },
            ],
            name: "setPayoutWallet",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "newOwner",
                type: "address",
              },
            ],
            name: "transferOwnership",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
          {
            inputs: [],
            name: "withdraw",
            outputs: [],
            stateMutability: "payable",
            type: "function",
          },
          {
            inputs: [],
            name: "communityPercent",
            outputs: [
              {
                internalType: "uint16",
                name: "",
                type: "uint16",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [
              {
                internalType: "address",
                name: "_contract",
                type: "address",
              },
            ],
            name: "getCreatorWallet",
            outputs: [
              {
                internalType: "address",
                name: "",
                type: "address",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "owner",
            outputs: [
              {
                internalType: "address",
                name: "",
                type: "address",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "payoutWallet",
            outputs: [
              {
                internalType: "address",
                name: "",
                type: "address",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "wrld",
            outputs: [
              {
                internalType: "contract kWRLD_Token_Ethereum",
                name: "",
                type: "address",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
        ];
        const purchaseOptions = {
          chain: "0x1",
          contractAddress: "0x22B03D85eeff6F5F0157c9B3981Ca2E5dE031922",
          functionName: "purchase",
          abi: marketPlaceAbi,
          params: {
            _seller: seller,
            _hostContract: hostContract,
            _price: price,
            _tokenId: tokenId,
            nounce: nounce,
            _creatorPercent: creatorPayout,
            _signature: sig,
          },
        };
        const purchase = await Moralis.executeFunction(purchaseOptions);
        await purchase.wait();
        self.buyApproveInProgress = false;
        self.buyApproved = false;
        this.$notify({
          title: "Congrats on your purchase!",
          text: "https://etherscan.io/tx/" + purchase.hash,
          type: "success",
        });
      } catch (e) {
        console.log(e);
        this.$notify({
          title: "Error with purchase",
          text: e.message,
          type: "error",
        });
      }
    },
    getUsdPriceEqv(listingPrice, wrldPrice) {
      return (parseFloat(listingPrice) * parseFloat(wrldPrice)).toFixed(2);
    },
  },
};
</script>
