<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-3 position-relative z-index-2 text-center mt-2">
        <div class="input-group input-group-static">
          <label class="outline">Monthly Marketplace Volume ($WRLD)</label>
          <input
            placeholder="Monthly Marketplace Volume"
            type="number"
            class="form-control b text-bold text-dark"
            v-model="volumeInput"
          />
        </div>
      </div>
      <div class="col-lg-3 position-relative z-index-2 text-center mt-2">
        <div class="input-group input-group-static">
          <label class="outline">Total AT Passports Staked (Personal)</label>
          <input
            placeholder="Total AT Passports Owned"
            type="number"
            class="form-control b text-bold text-dark"
            v-model="passesOwnedInput"
          />
        </div>
      </div>
      <div class="col-lg-3 position-relative z-index-2 text-center mt-2">
        <div class="input-group input-group-static">
          <label class="outline">Total Monthly Transactions</label>
          <input
            placeholder="Total Monthly Transactions"
            type="number"
            class="form-control b text-bold text-dark"
            v-model="totalTransactionsInput"
          />
        </div>
      </div>
      <div class="col-lg-3 position-relative z-index-2 text-center mt-2">
        <div class="input-group input-group-static">
          <label class="outline">Transactions You Performed</label>
          <input
            placeholder="Transactions You Performed"
            type="number"
            class="form-control b text-bold text-dark"
            v-model="transactionsPerformedInput"
            :max="totalTransactionsInput"
          />
        </div>
      </div>
      <div class="col-lg-3 position-relative z-index-2 text-center mt-2">
        <div class="input-group input-group-static">
          <label class="outline">Eligible Staked Passports (Community)</label>
          <input
            placeholder="Eligible Staked Passports"
            type="number"
            class="form-control b text-bold text-dark"
            v-model="stakedPassports"
            :max="1000"
          />
        </div>
      </div>
    </div>
    <div class="mt-4 row">
      <overview-card
        v-for="(overview, index) in getData"
        :key="index"
        :overviews="overview"
      />
    </div>
  </div>
</template>

<script>
import OverviewCard from "./dashboards/components/OverviewCard.vue";
import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "Calculator",
  data() {
    return {
      volumeInput: 300000,
      passesOwnedInput: 5,
      totalTransactionsInput: 10,
      transactionsPerformedInput: 1,
      stakedPassports: 500,
    };
  },
  computed: {
    getData() {
      const totalTransactionByUser =
        this.transactionsPerformedInput > this.totalTransactionsInput
          ? this.totalTransactionsInput
          : this.transactionsPerformedInput;
      const overviews = [
        {
          title: "Total Kickback Pool",
          value: (this.volumeInput * 0.05).toFixed(2),
        },
        {
          title: "Holder Allocation",
          value: "90%",
        },
        {
          title: "Buyer/Seller Allocation",
          value: "5%",
        },
        {
          title: "WRLDMind Allocation",
          value: "5%",
        },
        {
          title: "Holder Kickback Pool",
          value: (this.volumeInput * 0.05 * 0.9).toFixed(2),
        },
        {
          title: "Transaction Pool",
          value: (this.volumeInput * 0.05 * 0.05).toFixed(2),
        },
        {
          title: "WRLDMind Pool",
          value: (this.volumeInput * 0.05 * 0.05).toFixed(2),
        },
        {
          title: "Kickback Per Staked Passport",
          value: (
            (this.volumeInput * 0.05 * 0.9) /
            this.stakedPassports
          ).toFixed(2),
        },
        {
          title: "Kickback Per Transaction",
          value: (
            (this.volumeInput * 0.05 * 0.05) /
            (this.totalTransactionsInput * 2)
          ).toFixed(2),
        },
        {
          title: "Kickback from Transaction pool",
          value: (
            ((this.volumeInput * 0.05 * 0.05) /
              (this.totalTransactionsInput * 2)) *
            totalTransactionByUser
          ).toFixed(2),
        },
        {
          title: "Kickback from Holder pool",
          value: (
            ((this.volumeInput * 0.05 * 0.9) / 1000) *
            this.passesOwnedInput
          ).toFixed(2),
        },
        {
          title: "Total Kickback from All Pools",
          value: `${(
            ((this.volumeInput * 0.05 * 0.05) /
              (this.totalTransactionsInput * 2)) *
              totalTransactionByUser +
            ((this.volumeInput * 0.05 * 0.9) / this.stakedPassports) *
              this.passesOwnedInput
          ).toFixed(2)} $WRLD`,
        },
      ];
      return overviews;
    },
  },
  components: {
    OverviewCard,
  },
  mounted() {
    setTooltip();
  },
};
</script>
