import GrayBoys from "@/assets/img/collections/GrayBoys.gif";
import GrayBoysBanner from "@/assets/img/collections/GrayBoys-banner.png";

import NftWorlds from "@/assets/img/collections/nftworlds.png";
import NftWorldsBanner from "@/assets/img/collections/nftworldsbanner2.png";

import nftav from "@/assets/img/collections/nftav.gif";
import nftavBanner from "@/assets/img/collections/nftav-banner.png";

import wsp from "@/assets/img/collections/wsp.gif";
import wspBanner from "@/assets/img/collections/wsp-banner.png";

import wsv from "@/assets/img/collections/wsv.gif";
import wsvBanner from "@/assets/img/collections/wsv-banner.png";

import Wns from "@/assets/img/collections/wns.png";
import WnsBanner from "@/assets/img/collections/wns-banner.jpeg";

import WrldMindAT from "@/assets/img/collections/wm.png";
import WrldMindATBanner from "@/assets/img/collections/wm-banner.png";

import Cm from "@/assets/img/collections/cm.png";
import CmBanner from "@/assets/img/collections/cm-banner.png";

const contractToCollectionsMap = {
  "0xad1801d5d7a5f2ea3bc989a6540a81f46820fde3": {
    name: "WRLDMind",
    royalties: 100,
    contract: "0xad1801d5d7a5f2ea3bc989a6540a81f46820fde3",
    img: WrldMindAT,
    banner: WrldMindATBanner,
    owner: "0x7CB4F0d468806ABa9c9506e47fEb46b5CeFe4108",
    twitter: "https://twitter.com/wrldmind",
    discord: "https://discord.gg/3KX7GRZSEZv",
    website: "https://docs.wrldmind.com",
    description:
      "WRLDMind All-Terrain Passport - An entry pass into a Metaverse experience built inside NFTWorld#1879.",
    tokenSupply: 0,
  },
  "0x1ff8fe884db9124cc47405fd2859de1a8d9ce4e4": {
    name: "Collecting Meta",
    royalties: 0,
    contract: "0x1ff8fe884db9124cc47405fd2859de1a8d9ce4e4",
    img: Cm,
    banner: CmBanner,
    owner: "0xc2deb381314daf707a4609a55a6b93587bc94f3a",
    twitter: "https://twitter.com/collectingmeta",
    discord: "https://discord.gg/3KX7GRZSEZv",
    website: "https://upgrade.collectingmeta.com",
    description:
      "Collecting Meta will be the largest metaverse community wallet in the entire NFT industry. We are empowering our community with various metaverse platforms and the utility within them.",
    tokenSupply: 11244,
  },
  "0x8d4100897447d173289560bc85c5c432be4f44e4": {
    name: "Gray Boys",
    royalties: 100,
    contract: "0x8d4100897447d173289560bc85c5c432be4f44e4",
    img: GrayBoys,
    banner: GrayBoysBanner,
    owner: "0xEeB310C1Ff4637F1782666A8c173F97Dc70FC0D0",
    twitter: "https://twitter.com/grayboysdao",
    discord: "https://discord.gg/grayboys",
    website: "https://grayboysnft.com/",
    description:
      "Gray Boys are a collection of 10,000 generative NFTs that act as membership to The Mothership. The Gray Boys' Mothership is the first of its kind experiment to proxy NFT ownership verification, perks and utility of other blue chip projects to all Gray Boy holders. Gray Boy holders vote for what NFTs The Mothership's wallet acquires, and proxied ownership of those acquired NFTs is shared amongst all members.",
    tokenSupply: 10000,
  },
  "0xbd4455da5929d5639ee098abfaa3241e9ae111af": {
    name: "NFT Worlds",
    royalties: 0,
    contract: "0xbd4455da5929d5639ee098abfaa3241e9ae111af",
    img: NftWorlds,
    banner: NftWorldsBanner,
    owner: "0x2a52d32b2fB09492ddE1CA2c36E1c69531664461",
    twitter: "https://twitter.com/nftworldsNFT",
    discord: "https://discord.gg/nft-worlds",
    website: "https://docs.nftworlds.com/",
    description:
      "NFT Worlds is the most capable & flexible metaverse platform.",
    tokenSupply: 0,
  },
  "0x05745e72fb8b4a9b51118a168d956760e4a36444": {
    name: "NFT Worlds Genesis Avatars",
    royalties: 0,
    contract: "0x05745e72fb8b4a9b51118a168d956760e4a36444",
    img: nftav,
    banner: nftavBanner,
    owner: "0x2a52d32b2fB09492ddE1CA2c36E1c69531664461",
    twitter: "https://twitter.com/nftworldsNFT",
    discord: "https://discord.gg/nft-worlds",
    website: "https://nftworlds.com/avatars",
    description:
      "15,000 Genesis Avatars. Playable characters within the NFT Worlds ecosystem. Owning a Genesis Avatar gives access to a unique fully playable character, exclusive NFT Worlds item airdrops, $WRLD rewards, rare in-game titles and more.",
    tokenSupply: 0,
  },
  "0x7feb477600a03fd6ab1fe451cb3c7836a420f4ad": {
    name: "White Sands Parcel Pass",
    royalties: 0,
    contract: "0x7feb477600a03fd6ab1fe451cb3c7836a420f4ad",
    img: wsp,
    banner: wspBanner,
    owner: "0x814858Ad31778589a2049b3399963fB3ba942c76",
    twitter: "https://twitter.com/whitesandsgame",
    discord: "https://discord.gg/whitesands",
    website: " https://whitesands.game",
    description:
      "White Sands is your home in an open and evolving metaverse proudly built on NFT Worlds.",
    tokenSupply: 3000,
  },
  "0x6161235f0348bcf382390696e34792ecce0c47be": {
    name: "White Sands Islands Villas",
    royalties: 0,
    contract: "0x6161235f0348bcf382390696e34792ecce0c47be",
    img: wsv,
    banner: wsvBanner,
    owner: "0xC0259910244aF0c5De7aE3E520E4fd98D4b3C681",
    twitter: "https://twitter.com/whitesandsgame",
    discord: "https://discord.gg/whitesands",
    website: " https://whitesands.game",
    description:
      "Exclusive luxury villas in an open and evolving metaverse proudly built on NFT Worlds.",
    tokenSupply: 250,
  },
  "0xba4c1a3759c4e923191ac5664de3aee5cff1f20a": {
    name: "WRLD Name Service",
    royalties: 0,
    contract: "0xba4c1a3759c4e923191ac5664de3aee5cff1f20a",
    img: Wns,
    banner: WnsBanner,
    owner: "0x141a90f79db698a9efb1973785451bc94be26443",
    twitter: "https://twitter.com/WrldNameService",
    discord: "https://discord.gg/nft-worlds",
    website: "https://wrld.name",
    description: "Your gamertag & identity for all metaverses.",
    tokenSupply: 0,
  },
};

export default contractToCollectionsMap;
