<template>
  <div class="py-4 container-fluid">
    <div class="row mb-4">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6 mt-4">
            <mini-cards
              title="Marketplace WRLD Volume"
              v-bind:value="marketplaceVolume.toFixed(2)"
              iconName="show_chart"
              iconClass="text-white"
              iconBackground="bg-gradient-success"
              detail="Monthly"
            />
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 mt-4">
            <mini-cards
              title="Marketplace Volume $USD"
              v-bind:value="'$' + (marketplaceVolume * price).toFixed(2)"
              iconName="show_chart"
              iconClass="text-white"
              iconBackground="bg-gradient-success"
              detail="Monthly"
            />
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 mt-4">
            <mini-cards
              title="Total Marketplace Transactions"
              v-bind:value="totalTransactions"
              iconName="receipt_long"
              iconClass="text-white"
              iconBackground="bg-gradient-dark"
              detail="Monthly"
            />
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 mt-4">
            <mini-cards
              title="$WRLD Price"
              v-bind:value="'$' + price?.toFixed(4)"
              iconName="money"
              iconClass="text-white"
              iconBackground="bg-gradient-primary"
            />
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 mt-4">
            <mini-cards
              title="$WRLD Balance"
              v-bind:value="parseFloat(userBalance).toFixed(4)"
              iconName="paid"
              iconClass="text-white"
              iconBackground="bg-gradient-primary"
            />
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 mt-4">
            <mini-cards
              title="Your Transactions"
              v-bind:value="userTransTotal"
              iconName="point_of_sale"
              iconClass="text-white"
              iconBackground="bg-gradient-dark"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12 col-12 mb-4">
            <div class="card">
              <h4 class="text-center mt-4 mb-4">Recent Transactions</h4>
              <div class="table-responsive">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Collection
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                      >
                        Token Id
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Amount (WRLD)
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Purchased By
                      </th>
                      <th
                        class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Sold At
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="purchase in purchases"
                      :key="purchase.objectId"
                    >
                      <tr>
                        <td>
                          <div class="d-flex px-2 py-1">
                            <div>
                              <img
                                :src="
                                  Collections[purchase.attributes.token_address]
                                    .img
                                "
                                class="avatar avatar-sm me-3"
                              />
                            </div>
                            <div
                              class="d-flex flex-column justify-content-center"
                            >
                              <h6 class="mb-0 text-xs">
                                <a
                                  :href="
                                    'https://wrldmind.com/collection?contract=' +
                                    purchase.attributes.token_address.toLowerCase()
                                  "
                                >
                                  {{
                                    Collections[
                                      purchase.attributes.token_address
                                    ].name
                                  }}
                                </a>
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p class="text-xs font-weight-bold mb-0">
                            #{{ purchase.attributes.token_id }}
                          </p>
                        </td>
                        <td class="align-middle text-center text-sm">
                          <p class="text-xs text-success font-weight-bold mb-0">
                            {{ purchase.attributes.amount }}
                          </p>
                        </td>
                        <td class="align-middle text-center">
                          <span class="text-secondary text-xs"
                            ><a
                              :href="
                                'https://etherscan.io/address/' +
                                purchase.attributes.buyer
                              "
                              >{{ purchase.attributes.buyer }}</a
                            ></span
                          >
                        </td>
                        <td class="align-middle text-center">
                          <p class="text-xs text-info font-weight-bold mb-0">
                            {{ this.soldAtColValue(purchase) }}
                          </p>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import ChartBars from "./components/ChartBars.vue";
// import ChartLine from "./components/ChartLine.vue";
// import ChartLineTasks from "./components/ChartLineTasks.vue";
import MiniCards from "./components/MiniCards.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
// import OrdersCard from "./components/OrdersCard.vue";
// import Authenticate from "../components/Authenticate.vue";
// import useMoralis from "./composables/useMoralis";

// import Mint from "../components/Mint";
import Moralis from "moralis";
// import PlayerCountLineChart from "@/views/pages/components/PlayerCountLineChart";
// import NewPlayerCountLineChart from "@/views/pages/components/NewPlayerCountLineChart";
// import NewPlayerCountBarChart from "@/views/pages/components/NewPlayerCountBarChart";
import Collections from "../contractToCollectionMap";

export default {
  name: "dashboard-default",
  components: {
    // NewPlayerCountBarChart,
    // ChartBars,
    // ChartLine,
    // ChartLineTasks,
    MiniCards,
    // ProjectsCard,
    // OrdersCard,
    // Mint,
    // PlayerCountLineChart,
    // NewPlayerCountLineChart,
    // Authenticate,
  },
  data() {
    return {
      totalSupply: 0,
      price: 0,
      userBalance: 0,
      marketplaceVolume: 0,
      totalTransactions: 0,
      userTransTotal: 0,
      purchases: [],
      Collections,
    };
  },
  computed: {},
  methods: {
    soldAtColValue(purchase) {
      let timeUnit = "hour";
      let time = (
        Math.abs(Date.now() - purchase.attributes.sold_at) / 36e5
      ).toFixed(0);
      if (time > 23) {
        time = (time / 24).toFixed(0);
        timeUnit = "day";
      }
      const plural = Number(time) === 1 ? "" : "s";
      return `${time} ${timeUnit}${plural} ago`;
    },
  },
  mounted() {
    const abi = [
      {
        inputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "value",
            type: "uint256",
          },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "value",
            type: "uint256",
          },
        ],
        name: "Transfer",
        type: "event",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
        ],
        name: "allowance",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "cap",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "_amount",
            type: "uint256",
          },
          {
            internalType: "uint8",
            name: "_claimNonce",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "_signature",
            type: "bytes",
          },
        ],
        name: "claim",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "claimEnabled",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "decimals",
        outputs: [
          {
            internalType: "uint8",
            name: "",
            type: "uint8",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "subtractedValue",
            type: "uint256",
          },
        ],
        name: "decreaseAllowance",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "enableSecondClaim",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "addedValue",
            type: "uint256",
          },
        ],
        name: "increaseAllowance",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "mint",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "name",
        outputs: [
          {
            internalType: "string",
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "symbol",
        outputs: [
          {
            internalType: "string",
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "bool",
            name: "_claimEnabled",
            type: "bool",
          },
        ],
        name: "toggleClaim",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "totalSupply",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "transfer",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "transferFrom",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];
    try {
      Moralis.Web3API.token
        .getTokenPrice({
          address: "0xD5d86FC8d5C0Ea1aC1Ac5Dfab6E529c9967a45E9",
          chain: "0x1",
          exchange: "uniswap-v3",
        })
        .then((result) => {
          // eslint-disable-next-line no-undef
          this.price = result.usdPrice;
        });
      if (Moralis.User.current()) {
        const balanceOptions = {
          chain: "0x1",
          address: "0xD5d86FC8d5C0Ea1aC1Ac5Dfab6E529c9967a45E9",
          function_name: "balanceOf",
          abi: abi,
          params: {
            account: Moralis.User.current().get("ethAddress"),
          },
        };
        Moralis.Web3API.native
          .runContractFunction(balanceOptions)
          .then((data) => {
            this.userBalance = Moralis.Units.FromWei(data);
          });
      }
      // pull listing
      const date = new Date();
      const Listing = Moralis.Object.extend("Listing");
      const query = new Moralis.Query(Listing);
      query.greaterThan(
        "sold_at",
        new Date(date.getFullYear(), date.getMonth(), 1)
      );
      query.find().then((listings) => {
        this.totalTransactions = listings.length;
        const sortedListings = listings.sort((a, b) => {
          return b.updatedAt > a.updatedAt ? 1 : -1;
        });
        this.purchases = sortedListings;
        for (let i = 0; i < listings.length; i++) {
          this.marketplaceVolume =
            this.marketplaceVolume + listings[i].attributes.amount;
          if (Moralis.User.current()) {
            if (
              listings[i].attributes.seller ===
                Moralis.User.current().get("ethAddress") ||
              listings[i].attributes.buyer ===
                Moralis.User.current().get("ethAddress")
            ) {
              this.userTransTotal = this.userTransTotal + 1;
            }
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  },
};
</script>
